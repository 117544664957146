import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	// This data will depend on which action was called
	reportData: { feedbacks: [], count: 0 },
	users: [],
	reportStatistic: null,
	processing: false,
	processingStatistic: false,
	processed: false,
	error: null
};

const reportsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_DOWNER_REPORT:
			return {
				...state,
				error: null,
				reportData: { feedbacks: [], count: 0 },
				processing: true,
				processed: false
			};
		case actionTypes.LOAD_DOWNER_REPORT_SUCCESS:
			return {
				...state,
				processed: true,
				processing: false,
				error: null,
				reportData: action.payload
			};
		case actionTypes.LOAD_DOWNER_REPORT_FAIL:
			return {
				...state,
				processed: true,
				processing: false,
				error: action.payload,
				reportData: { feedbacks: [], count: 0 }
			};
		case actionTypes.LOAD_DOWNER_STATISTICAL_REPORT:
			return {
				...state,
				error: null,
				reportStatistic: null,
				processingStatistic: true,
				processed: false
			};
		case actionTypes.LOAD_DOWNER_STATISTICAL_REPORT_SUCCESS:
			return {
				...state,
				processed: true,
				processingStatistic: false,
				error: null,
				reportStatistic: action.payload
			};
		case actionTypes.LOAD_DOWNER_STATISTICAL_REPORT_FAIL:
			return {
				...state,
				processed: true,
				processingStatistic: false,
				error: action.payload,
				reportStatistic: null
			};
		case actionTypes.LOAD_USER_DOWNER_STATISTICAL_REPORT:
			return {
				...state,
				error: null,
				users: [],
				processing: true,
				processed: false
			};
		case actionTypes.LOAD_USER_DOWNER_STATISTICAL_REPORT_SUCCESS:
			return {
				...state,
				processed: true,
				processing: false,
				error: null,
				users: action.payload
			};
		case actionTypes.LOAD_USER_DOWNER_STATISTICAL_REPORT_FAIL:
			return {
				...state,
				processed: true,
				processing: false,
				error: action.payload,
				users: []
			};
		default:
			return state;
	}
};

export default reportsReducer;
