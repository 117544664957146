import { createSelector } from 'reselect';

export const getCategoryState = (state) => state.category;

export const getCategoriesProcessing = createSelector(getCategoryState, (category) => category.processing);

export const getCategoriesProcessed = createSelector(getCategoryState, (category) => category.processed);

export const getCategoriesError = createSelector(getCategoryState, (category) => category.error);

export const getCategories = createSelector(getCategoryState, (category) => category.categories);

export const getCategory = createSelector(getCategoryState, (category) => category.category);
