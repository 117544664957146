import { createSelector } from 'reselect';

export const getUsageLogsState = (state) => state.logs;

export const getUsageLogsProcessing = createSelector(getUsageLogsState, (logs) => logs.processing);

export const getUsageLogsProcessed = createSelector(getUsageLogsState, (logs) => logs.processed);
export const getUsageLogsError = createSelector(getUsageLogsState, (logs) => logs.error);

export const getUsageLogsTopDestinations = createSelector(getUsageLogsState, (logs) => logs.topDestinations);

export const getUsageLogsTopCategories = createSelector(getUsageLogsState, (logs) => logs.topCategories);
