import React from 'react';
import Delaunator from 'delaunator';

// setting
const particleCount = 40,
	flareCount = 10,
	motion = 0.05,
	tilt = 0.05,
	color = '#FFEED4',
	particleSizeBase = 1,
	particleSizeMultiplier = 0.5,
	flareSizeBase = 100,
	flareSizeMultiplier = 100,
	lineWidth = 1,
	linkChance = 75, // chance per frame of link, higher = smaller chance
	linkLengthMin = 5, // min linked vertices
	linkLengthMax = 7, // max linked vertices
	linkOpacity = 0.25, // number between 0 & 1
	linkFade = 90, // link fade-out frames
	linkSpeed = 1, // distance a link travels in 1 frame
	glareAngle = -60,
	glareOpacityMultiplier = 0.05,
	renderParticles = true,
	renderParticleGlare = true,
	renderFlares = true,
	renderLinks = true,
	renderMesh = false,
	flicker = true,
	flickerSmoothing = 15, // higher = smoother flicker
	blurSize = 0,
	orbitTilt = true,
	randomMotion = true,
	noiseLength = 1000,
	noiseStrength = 1;

function StarBackground() {
	// Use useRef for mutable variables that we want to persist
	// without triggering a re-render on their change
	const requestRef = React.useRef();
	const previousTimeRef = React.useRef();
	const canvasRef = React.useRef();

	const animate = () => {
		// requestAnimationFrame(animate(canvas, context));
		// if (previousTimeRef.current !== undefined) {
		//   const deltaTime = time - previousTimeRef.current;
		//
		//   // Pass on a function to the setter of the state
		//   // to make sure we always have the latest state
		//   setCount(prevCount => (prevCount + deltaTime * 0.001) % 100);
		// }
		// console.log('time', time);
		// previousTimeRef.current = time;
		// setInterval(function(){ requestRef.current = requestAnimationFrame(animate); }, 3000);
		// requestRef.current = requestAnimationFrame(animate);
	};

	let mouse = { x: 0, y: 0 },
		m = {},
		r = 0,
		c = 1000, // multiplier for delaunay points, since floats too small can mess up the algorithm
		n = 0,
		nAngle = (Math.PI * 2) / noiseLength,
		nRad = 100,
		nScale = 0.5,
		nPos = { x: 0, y: 0 },
		points = [],
		vertices = [],
		triangles = [],
		links = [],
		particles = [],
		flares = [];

	const initValues = () => {
		mouse = { x: 0, y: 0 };
		m = {};
		r = 0;
		c = 1000; // multiplier for delaunay points, since floats too small can mess up the algorithm
		n = 0;
		nAngle = (Math.PI * 2) / noiseLength;
		nRad = 100;
		nScale = 0.5;
		nPos = { x: 0, y: 0 };
		points = [];
		vertices = [];
		triangles = [];
		links = [];
		particles = [];
		flares = [];
	};

	const initParticle = () => {
		return {
			x: random(-0.1, 1.1, true),
			y: random(-0.1, 1.1, true),
			z: random(0, 4),
			color: color,
			opacity: random(0.1, 1, true),
			flicker: 0,
			neighbors: []
		};
	};

	const render = (canvas, context) => {
		if (randomMotion) {
			n++;
			if (n >= noiseLength) {
				n = 0;
			}

			nPos = noisePoint(n);
			//console.log('NOISE x:'+nPos.x+' y:'+nPos.y);
		}

		// Clear
		context.clearRect(0, 0, canvas.width, canvas.height);

		if (blurSize > 0) {
			context.shadowBlur = blurSize;
			context.shadowColor = color;
		}

		if (renderParticles) {
			// Render particles
			for (let i = 0; i < particleCount; i++) {
				renderParticle(particles[i], canvas, context);
			}
		}

		if (renderMesh) {
			// Render all lines
			context.beginPath();
			for (let v = 0; v < vertices.length - 1; v++) {
				// Splits the array into triplets
				if ((v + 1) % 3 === 0) {
					continue;
				}

				let p1 = particles[vertices[v]],
					p2 = particles[vertices[v + 1]];

				//console.log('Line: '+p1.x+','+p1.y+'->'+p2.x+','+p2.y);

				let pos1 = position(p1.x, p1.y, p1.z, canvas),
					pos2 = position(p2.x, p2.y, p2.z, canvas);

				context.moveTo(pos1.x, pos1.y);
				context.lineTo(pos2.x, pos2.y);
			}
			context.strokeStyle = color;
			context.lineWidth = lineWidth;
			context.stroke();
			context.closePath();
		}

		if (renderLinks) {
			// Possibly start a new link
			if (random(0, linkChance) === linkChance) {
				let length = random(linkLengthMin, linkLengthMax);
				let start = random(0, particles.length - 1);
				startLink(start, length);
			}

			// Render existing links
			// Iterate in reverse so that removing items doesn't affect the loop
			for (let l = links.length - 1; l >= 0; l--) {
				if (links[l] && !links[l].finished) {
					renderLink(links[l], canvas, context);
				} else {
					delete links[l];
				}
			}
		}

		if (renderFlares) {
			// Render flares
			for (let j = 0; j < flareCount; j++) {
				renderFlare(flares[j], canvas, context);
			}
		}
	};

	const resize = (canvas) => {
		canvas.width = window.innerWidth * (window.devicePixelRatio || 1);
		canvas.height = window.innerHeight * (window.devicePixelRatio || 1);
	};

	const startLink = (vertex, length) => {
		links.push(initLink(vertex, length));
	};

	const renderParticle = (particle, canvas, context) => {
		var pos = position(particle.x, particle.y, particle.z, canvas),
			r = (particle.z * particleSizeMultiplier + particleSizeBase) * (sizeRatio(canvas) / 1000),
			o = particle.opacity;

		if (flicker) {
			var newVal = random(-0.5, 0.5, true);
			particle.flicker += (newVal - particle.flicker) / flickerSmoothing;
			if (particle.flicker > 0.5) particle.flicker = 0.5;
			if (particle.flicker < -0.5) particle.flicker = -0.5;
			o += particle.flicker;
			if (o > 1) o = 1;
			if (o < 0) o = 0;
		}

		context.fillStyle = particle.color;
		context.globalAlpha = o;
		context.beginPath();
		context.arc(pos.x, pos.y, r, 0, 2 * Math.PI, false);
		context.fill();
		context.closePath();

		if (renderParticleGlare) {
			context.globalAlpha = o * glareOpacityMultiplier;

			context.ellipse(pos.x, pos.y, r * 100, r, (glareAngle - (nPos.x - 0.5) * noiseStrength * motion) * (Math.PI / 180), 0, 2 * Math.PI, false);
			context.fill();
			context.closePath();
		}

		context.globalAlpha = 1;
	};

	const initFlare = () => {
		return {
			x: random(-0.25, 1.25, true),
			y: random(-0.25, 1.25, true),
			z: random(0, 2),
			color: color,
			opacity: random(0.001, 0.01, true)
		};
	};

	const renderFlare = (flare, canvas, context) => {
		var pos = position(flare.x, flare.y, flare.z, canvas),
			r = (flare.z * flareSizeMultiplier + flareSizeBase) * (sizeRatio(canvas) / 1000);

		context.beginPath();
		context.globalAlpha = flare.opacity;
		context.arc(pos.x, pos.y, r, 0, 2 * Math.PI, false);
		context.fillStyle = flare.color;
		context.fill();
		context.closePath();
		context.globalAlpha = 1;
	};

	const initLink = (startVertex, numPoints) => {
		return {
			length: numPoints,
			verts: [startVertex],
			stage: 0,
			linked: [startVertex],
			distances: [],
			traveled: 0,
			fade: 0,
			finished: false
		};
	};

	const renderLink = (link, canvas, context) => {
		let i, p, pos, points;
		switch (link.stage) {
			// VERTEX COLLECTION STAGE
			case 0:
				// Grab the last member of the link
				let last = particles[link.verts[link.verts.length - 1]];
				//console.log(JSON.stringify(last));
				if (last && last.neighbors && last.neighbors.length > 0) {
					// Grab a random neighbor
					let neighbor = last.neighbors[random(0, last.neighbors.length - 1)];
					// If we haven't seen that particle before, add it to the link
					if (link.verts.indexOf(neighbor) === -1) {
						link.verts.push(neighbor);
					}
					// If we have seen that particle before, we'll just wait for the next frame
				} else {
					//console.log(this.verts[0]+' prematurely moving to stage 3 (0)');
					link.stage = 3;
					link.finished = true;
				}

				if (link.verts.length >= link.length) {
					// Calculate all distances at once
					for (i = 0; i < link.verts.length - 1; i++) {
						let p1 = particles[link.verts[i]],
							p2 = particles[link.verts[i + 1]],
							dx = p1.x - p2.x,
							dy = p1.y - p2.y,
							dist = Math.sqrt(dx * dx + dy * dy);

						link.distances.push(dist);
					}
					link.stage = 1;
				}
				break;

			// RENDER LINE ANIMATION STAGE
			case 1:
				if (link.distances.length > 0) {
					points = [];
					//var a = 1;

					// Gather all points already linked
					for (i = 0; i < link.linked.length; i++) {
						p = particles[link.linked[i]];
						pos = position(p.x, p.y, p.z, canvas);
						points.push([pos.x, pos.y]);
					}

					let linkSpeedRel = linkSpeed * 0.00001 * canvas.width;
					link.traveled += linkSpeedRel;
					let d = link.distances[link.linked.length - 1];
					// Calculate last point based on linkSpeed and distance travelled to next point
					if (link.traveled >= d) {
						link.traveled = 0;
						// We've reached the next point, add coordinates to array
						//console.log(this.verts[0]+' reached vertex '+(this.linked.length+1)+' of '+this.verts.length);

						link.linked.push(link.verts[link.linked.length]);
						p = particles[link.linked[link.linked.length - 1]];
						pos = position(p.x, p.y, p.z, canvas);
						points.push([pos.x, pos.y]);

						if (link.linked.length >= link.verts.length) {
							//console.log(this.verts[0]+' moving to stage 2 (1)');
							link.stage = 2;
						}
					} else {
						// We're still travelling to the next point, get coordinates at travel distance
						// http://math.stackexchange.com/a/85582
						let a = particles[link.linked[link.linked.length - 1]],
							b = particles[link.verts[link.linked.length]],
							t = d - link.traveled,
							x = (link.traveled * b.x + t * a.x) / d,
							y = (link.traveled * b.y + t * a.y) / d,
							z = (link.traveled * b.z + t * a.z) / d;

						pos = position(x, y, z, canvas);

						//console.log(this.verts[0]+' traveling to vertex '+(this.linked.length+1)+' of '+this.verts.length+' ('+this.traveled+' of '+this.distances[this.linked.length]+')');

						points.push([pos.x, pos.y]);
					}

					drawLineLink(context, points);
				} else {
					//console.log(this.verts[0]+' prematurely moving to stage 3 (1)');
					link.stage = 3;
					link.finished = true;
				}
				break;

			// FADE OUT STAGE
			case 2:
				if (link.verts.length > 1) {
					if (link.fade < linkFade) {
						link.fade++;

						// Render full link between all vertices and fade over time
						points = [];
						let alpha = (1 - link.fade / linkFade) * linkOpacity;
						for (i = 0; i < link.verts.length; i++) {
							p = particles[link.verts[i]];
							pos = position(p.x, p.y, p.z, canvas);
							points.push([pos.x, pos.y]);
						}
						drawLineLink(context, points, alpha);
					} else {
						//console.log(this.verts[0]+' moving to stage 3 (2a)');
						link.stage = 3;
						link.finished = true;
					}
				} else {
					//console.log(this.verts[0]+' prematurely moving to stage 3 (2b)');
					link.stage = 3;
					link.finished = true;
				}
				break;

			// FINISHED STAGE
			case 3:
			default:
				link.finished = true;
				break;
		}
	};

	const drawLineLink = (context, points, alpha = 0) => {
		if (typeof alpha !== 'number') alpha = linkOpacity;

		if (points.length > 1 && alpha > 0) {
			context.globalAlpha = alpha;
			context.beginPath();
			for (let i = 0; i < points.length - 1; i++) {
				context.moveTo(points[i][0], points[i][1]);
				context.lineTo(points[i + 1][0], points[i + 1][1]);
			}
			context.strokeStyle = color;
			context.lineWidth = lineWidth;
			context.stroke();
			context.closePath();
			context.globalAlpha = 1;
		}
	};

	const noisePoint = (i) => {
		let a = nAngle * i,
			cosA = Math.cos(a),
			sinA = Math.sin(a),
			//value = simplex.noise2D(nScale * cosA + nScale, nScale * sinA + nScale),
			//rad = nRad + value;
			rad = nRad;
		return {
			x: rad * cosA,
			y: rad * sinA
		};
	};

	const position = (x, y, z, canvas) => {
		return {
			x: x * canvas.width + (canvas.width / 2 - mouse.x + (nPos.x - 0.5) * noiseStrength) * z * motion,
			y: y * canvas.height + (canvas.height / 2 - mouse.y + (nPos.y - 0.5) * noiseStrength) * z * motion
		};
	};

	const sizeRatio = (canvas) => {
		return canvas.width >= canvas.height ? canvas.width : canvas.height;
	};

	const random = (min, max, float) => {
		return float ? Math.random() * (max - min) + min : Math.floor(Math.random() * (max - min + 1)) + min;
	};

	React.useEffect(() => {
		let canvas = canvasRef.current;
		let context = canvas.getContext('2d');

		let requestId;
		const init = () => {
			let i, j, k;

			// Size canvas
			resize(canvas);

			mouse.x = canvas.clientWidth / 2;
			mouse.y = canvas.clientHeight / 2;

			// Create particle positions
			for (i = 0; i < particleCount; i++) {
				let p = initParticle();
				particles.push(p);
				points.push([p.x * c, p.y * c]);
			}

			// const triangulate = require("delaunay-triangulate");

			const delaunay = Delaunator.from(points);
			vertices = delaunay.triangles;
			// vertices = triangulate(points);
			// Create an array of "triangles" (groups of 3 indices)
			let tri = [];
			for (i = 0; i < vertices.length; i++) {
				if (tri.length === 3) {
					triangles.push(tri);
					tri = [];
				}
				tri.push(vertices[i]);
			}

			// Tell all the particles who their neighbors are
			for (i = 0; i < particles.length; i++) {
				// Loop through all tirangles
				for (j = 0; j < triangles.length; j++) {
					// Check if this particle's index is in this triangle
					k = triangles[j].indexOf(i);
					// If it is, add its neighbors to the particles contacts list
					if (k !== -1) {
						triangles[j].forEach(function (value, index, array) {
							if (value !== i && particles[i].neighbors.indexOf(value) === -1) {
								particles[i].neighbors.push(value);
							}
						});
					}
				}
			}

			if (renderFlares) {
				// Create flare positions
				for (i = 0; i < flareCount; i++) {
					flares.push(initFlare());
				}
			}

			// Motion mode
			if ('ontouchstart' in document.documentElement && window.DeviceOrientationEvent) {
				console.log('Using device orientation');
				window.addEventListener(
					'deviceorientation',
					function (e) {
						mouse.x = canvas.clientWidth / 2 - (e.gamma / 90) * (canvas.clientWidth / 2) * 2;
						mouse.y = canvas.clientHeight / 2 - (e.beta / 90) * (canvas.clientHeight / 2) * 2;
					},
					true
				);
			} else {
				// Mouse move listener
				console.log('Using mouse movement');
				document.body.addEventListener('mousemove', function (e) {
					//console.log('moved');
					mouse.x = e.clientX;
					mouse.y = e.clientY;
				});
			}
		};

		init();

		const initStatus = () => {
			resize(canvas);
			render(canvas, context);

			requestId = requestAnimationFrame(initStatus);
		};

		initStatus();
		return () => {
			cancelAnimationFrame(requestId);
		};

		// setInterval(function() {
		//   // animiate something
		//   resize(canvas);
		//   render(canvas, context);
		// }, 1000/60);
		// requestAnimationFrame(animate(canvas, context));
		// return () => cancelAnimationFrame(requestRef.current);
		// canvasRef.current = requestAnimationFrame(init(canvas, context).animate(canvas, context));
	});

	return (
		<>
			<canvas id="stars" ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />
		</>
	);
}

export default StarBackground;
