import { createSelector } from 'reselect';

export const getMetaTagState = (state) => state.metaTag;

export const getMetaTagsProcessing = createSelector(getMetaTagState, (metaTag) => metaTag.processing);

export const getMetaTagsProcessed = createSelector(getMetaTagState, (metaTag) => metaTag.processed);

export const getMetaTagsError = createSelector(getMetaTagState, (metaTag) => metaTag.error);

export const getMetaTags = createSelector(getMetaTagState, (metaTag) => metaTag.metaTags);

export const getMetaTag = createSelector(getMetaTagState, (metaTag) => metaTag.metaTag);
