import React, { PureComponent } from 'react';
import LoginCard from '../../../shared/components/login/LoginCard';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { tryLocalSignIn } from '../../../redux/actions/authActions';
import ForgotPasswordCard from './components/ForgotPasswordCard';
import StarBackground from '../LogIn/components/StarBackground';

class ForgotPassword extends PureComponent {
	componentWillMount() {}
	render() {
		return (
			<div className="star-background account account--not-photo">
				<StarBackground />
				<ForgotPasswordCard />
			</div>
		);
	}
}

export default compose(connect(null, { tryLocalSignIn })(ForgotPassword));
