import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	map: {},
	maps: [],
	mapData: [],
	mapLinks: [],
	tenantData: [],
	devicesData: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null, // Used to redirect users to the page they visited before logging in
	currentMapId: null
};

const mapReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				maps: [],
				map: {},
				mapData: [],
				mapLinks: [],
				tenantData: [],
				devicesData: []
			};
		case actionTypes.LOAD_MAPS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				maps: []
			};
		case actionTypes.LOAD_MAP:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				map: {},
				mapData: [],
				mapLinks: [],
				tenantData: [],
				devicesData: []
			};
		case actionTypes.ADD_MAP:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.DELETE_MAP:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.UPDATE_MAP:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_MAPS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				maps: action.payload.maps,
				defaultPath: '/login'
			};

		case actionTypes.LOAD_MAP_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				map: { ...action.payload.map }
			};

		case actionTypes.DELETE_MAP_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_MAP_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				map: { ...action.payload.map }
			};
		case actionTypes.UPDATE_MAP_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true
			};
		case actionTypes.UPDATE_ACTIVE_MAP_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				maps: action.payload.maps
			};
		case actionTypes.LOAD_MAPS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.LOAD_MAP_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_MAP_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_MAP_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};

		case actionTypes.GET_MAP_DATA_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};

		case actionTypes.GET_MAP_DATA:
			return {
				...state,
				processing: false,
				processed: true,
				mapData: [],
				mapLinks: [],
				tenantData: [],
				map: {},
				devicesData: []
			};
		case actionTypes.GET_MAP_DATA_SUCCESS:
			console.log('GET_MAP_DATA_SUCCESS', action.payload);
			return {
				...state,
				processing: false,
				processed: true,
				mapData: { ...action.payload.mapData },
				mapLinks: { ...action.payload.mapLinks },
				tenantData: { ...action.payload.tenantData },
				map: { ...action.payload.map },
				devicesData: { ...action.payload.devices }
			};
		case actionTypes.SET_CURRENT_MAP:
			return {
				...state,
				currentMapId: action.payload
			};
		default:
			return state;
	}
};

export default mapReducer;
