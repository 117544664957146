import { createSelector } from 'reselect';

const getDownerReportState = state => state.downer;

export const getReportData = createSelector(getDownerReportState, report => report.reportData);
export const getReportError = createSelector(getDownerReportState, report => report.error);
export const getProcessing = createSelector(getDownerReportState, report => report.processing);
export const getProcessingStatistic = createSelector(getDownerReportState, report => report.processingStatistic);
export const getReportStatistical = createSelector(getDownerReportState, report => report.reportStatistic);
export const getUserReport = createSelector(getDownerReportState, report => report.users);
