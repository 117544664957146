import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	event: {},
	events: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const eventsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				event: {},
				events: []
			};
		case actionTypes.LOAD_EVENTS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_EVENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				event: {}
			};
		case actionTypes.ADD_EVENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				event: {}
			};
		case actionTypes.UPDATE_EVENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.DELETE_EVENTS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_EVENTS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				events: action.payload.events,
				defaultPath: '/dashboard'
			};
		case actionTypes.LOAD_EVENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				event: { ...action.payload.event }
			};
		case actionTypes.ADD_EVENT_SUCCESS:
		case actionTypes.UPDATE_EVENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				event: { ...action.payload.event }
			};
		case actionTypes.DELETE_EVENTS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				events: action.payload.events
			};
		case actionTypes.LOAD_EVENT_FAIL:
		case actionTypes.ADD_EVENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_EVENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.DELETE_EVENTS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default eventsReducer;
