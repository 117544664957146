import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	defaultPath: '/login',
	processing: false,
	switch_client: null
};

const switchClientReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				processing: false,
				switch_client: { ...action.payload }
			};
		case actionTypes.SWITCH_CLIENT_PROCESSING:
			return {
				...state,
				processing: true
			};
		default:
			return state;
	}
};

export default switchClientReducer;
