import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { tryLocalSignIn } from '../../../redux/actions/authActions';
import ResetPasswordCard from './components/ResetPasswordCard';
import StarBackground from '../LogIn/components/StarBackground';

class ResetPassword extends PureComponent {
	componentWillMount() {}
	render() {
		return (
			<div className="star-background account account--not-photo">
				<StarBackground />
				<ResetPasswordCard />
			</div>
		);
	}
}

export default compose(connect(null, { tryLocalSignIn })(ResetPassword));
