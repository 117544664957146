import * as actionTypes from './types';
import { debounce, throttle } from 'lodash';
import { getToken } from '../selectors/auth.selectors';
const loadMapsSuccess = (payload) => ({
	type: actionTypes.LOAD_MAPS_SUCCESS,
	payload
});

const loadMapsFail = (payload) => ({
	type: actionTypes.LOAD_MAPS_FAIL,
	payload
});

export const loadMaps = () => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.LOAD_MAPS });
	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(loadMapsFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	return mernApi.get('/maps').then(
		(response) => {
			dispatch(loadMapsSuccess(response.data));
		},
		(err) => {
			if (err.response && err.response.data) {
				dispatch(loadMapsFail(err.response.data.error));
			} else {
				dispatch(loadMapsFail('Server error'));
			}
		}
	);
};

export const _innerLoadClientMaps = throttle(
	(client, dispatch, getState, { mernApi }) => {
		dispatch({ type: actionTypes.LOAD_MAPS });

		// const authInfo = JSON.parse(localStorage.getItem('authInfo'));
		// const now = Math.floor(Date.now() / 1000);
		// if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		// 	dispatch(loadMapsFail());
		// 	return Promise.resolve();
		// }
		let token = getToken(getState());
		if (!token) {
			dispatch(loadMapsFail());
			return Promise.resolve();
		}
		mernApi.setAuthToken(token);
		let clientId = {};
		clientId['ids'] = client._id;

		return mernApi.post(`/maps/client`, clientId).then(
			(response) => {
				dispatch(loadMapsSuccess(response.data));
			},
			(err) => {
				if (err.response) {
					dispatch(loadMapsFail(err.response.data.error));
				} else {
					dispatch(deleteMapFail('Server error'));
				}
			}
		);
	},
	300,
	{ leading: true, trailing: false }
);

export const loadClientMaps = (client) => (dispatch, getState, { mernApi }) => _innerLoadClientMaps(client, dispatch, getState, { mernApi });

const loadMapSuccess = (payload) => ({
	type: actionTypes.LOAD_MAP_SUCCESS,
	payload
});

const loadMapFail = (payload) => ({
	type: actionTypes.LOAD_MAP_FAIL,
	payload
});

export const loadMap = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.LOAD_MAP });
	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(loadMapsFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	return mernApi.get(`/maps/${formValues.id}`).then(
		(response) => {
			dispatch(loadMapSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(loadMapFail(err.response.data.error));
			} else {
				dispatch(deleteMapFail('Server error'));
			}
		}
	);
};

const deleteMapSuccess = (payload) => ({
	type: actionTypes.DELETE_MAP_SUCCESS,
	payload
});

const deleteMapFail = (payload) => ({
	type: actionTypes.DELETE_MAP_FAIL,
	payload
});

export const deleteMap = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.DELETE_MAP });
	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(loadMapsFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	return mernApi.delete(`/maps/${formValues.id}`).then(
		(response) => {
			dispatch(deleteMapSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(deleteMapFail(err.response.data.error));
			} else {
				dispatch(deleteMapFail('Server error'));
			}
		}
	);
};

const addMapSuccess = (payload) => ({
	type: actionTypes.ADD_MAP_SUCCESS,
	payload
});

const addMapFail = (payload) => ({
	type: actionTypes.ADD_MAP_FAIL,
	payload
});

export const addMap = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.ADD_MAP });

	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(addMapFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	const formData = new FormData();
	formData.append('mapName', formValues.mapName);
	formData.append('outdoorMapWidth', formValues.outdoorMapWidth);
	formData.append('indoorMapWidth', formValues.indoorMapWidth);
	formData.append('stairHeight', formValues.stairHeight);
	formData.append('anchor', formValues.anchor || '');

	if (formValues.cid.value) {
		formData.append('cid', formValues.cid.value);
	} else {
		if (formValues.cid) {
			formData.append('cid', formValues.cid);
		} else {
			dispatch(addMapFail('Empty the client information'));
			return Promise.resolve();
		}
	}

	if (formValues.svg && formValues.svg.length > 0) {
		formData.append('svg', formValues.svg[0]);
	} else {
		dispatch(addMapFail('Svg icon is not assigned'));
		return Promise.resolve();
	}

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};
	return mernApi.post('/maps/add', formData, config).then(
		(response) => {
			dispatch(addMapSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(addMapFail(err.response.data.error));
			} else {
				dispatch(addMapFail('SERVER_ERROR'));
			}
		}
	);
};

const updateMapSuccess = (payload) => ({
	type: actionTypes.UPDATE_MAP_SUCCESS,
	payload
});

const updateActiveMapSuccess = (payload) => ({
	type: actionTypes.UPDATE_ACTIVE_MAP_SUCCESS,
	payload
});

const updateMapFail = (payload) => ({
	type: actionTypes.UPDATE_MAP_FAIL,
	payload
});

export const updateMapInfo = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.UPDATE_MAP });

	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(updateMapFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	const formData = new FormData();
	formData.append('mapName', formValues.mapName);
	formData.append('outdoorMapWidth', formValues.outdoorMapWidth);
	formData.append('indoorMapWidth', formValues.indoorMapWidth);
	formData.append('anchor', formValues.anchor || '');
	formData.append('anchorSVG', formValues.anchorSVG || '');
	formData.append('anchorScale', formValues.anchorScale || '');

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};
	return mernApi.put(`/maps/${formValues._id}`, formData, config).then(
		(response) => {
			dispatch(updateMapSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(updateMapFail(err.response.data.error));
			} else {
				dispatch(updateMapFail('SERVER_ERROR'));
			}
		}
	);
};

export const updateMap = (map) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.UPDATE_MAP });

	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(updateMapFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	const formValues = {
		indoorSupport: map.indoorSupport,
		outdoorSupport: map.outdoorSupport,
		active: map.active
	};

	if (map.mapContent) {
		formValues['mapContent'] = map.mapContent;
	}

	if (map.svgWidth) {
		formValues['svgWidth'] = map.svgWidth;
	}

	return mernApi.put(`/maps/${map._id}`, formValues).then(
		(response) => {
			dispatch(updateActiveMapSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(updateMapFail(err.response.data.error));
			} else {
				dispatch(updateMapFail('Server error'));
			}
		}
	);
};

export const updatePlacesMap = (map) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.UPDATE_MAP });

	return mernApi.post(`/maps/update`, map).then(
		(response) => {
			console.log('update map success');
			dispatch(updateMapSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				console.log('update map error');
				dispatch(updateMapFail(err.response.data.error));
			} else {
				dispatch(updateMapFail('Server error'));
			}
		}
	);
};

const getMapDataSuccess = (payload) => ({
	type: actionTypes.GET_MAP_DATA_SUCCESS,
	payload
});

const getMapDataFail = (payload) => ({
	type: actionTypes.GET_MAP_DATA_FAIL,
	payload
});

export const getPlacesMapData = (mapId) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.GET_MAP_DATA });

	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(loadMapsFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	return mernApi.post(`/maps/mapData`, mapId).then(
		(response) => {
			dispatch(getMapDataSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(getMapDataFail(err.response.data.error));
			} else {
				dispatch(getMapDataFail('Server error'));
			}
		}
	);
};

export const getClientMapAllData = (cid) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.GET_MAP_DATA });

	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(loadMapsFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);

	return mernApi.post(`/maps/clientMap`, { cid }).then(
		(response) => {
			console.log('getClientMapAllData ok');
			dispatch(getMapDataSuccess(response.data));
		},
		(err) => {
			if (err.response) {
				dispatch(getMapDataFail(err.response.data.error));
			} else {
				dispatch(getMapDataFail('Server error'));
			}
		}
	);
};

const getCurrentMap = (payload) => ({
	type: actionTypes.SET_CURRENT_MAP,
	payload
});

export const setCurrentMap = (mapId) => (dispatch) => {
	dispatch(getCurrentMap(mapId));
};
