import { createSelector } from 'reselect';

export const getEventState = (state) => state.event;

export const getEventsProcessing = createSelector(getEventState, (event) => event.processing);

export const getEventsProcessed = createSelector(getEventState, (event) => event.processed);

export const getEventsError = createSelector(getEventState, (event) => event.error);

export const getEvents = createSelector(getEventState, (event) => event.events);

export const getEvent = createSelector(getEventState, (event) => event.event);
