import React from 'react';
import { connect } from 'react-redux';
import { getCurrentUser, getIsSignedIn, getDefaultPath, getSwitchClient, getClient, getClientsError, getClientsProcessing } from '../redux/selectors';
import { setAttemptedPath, setSwitchClient } from '../redux/actions/authActions';
import { withRouter } from 'react-router-dom';
import { loadClient, removeClientError } from '../redux/actions/clientsActions';
import isEqual from 'lodash/isEqual';
/**
 * If role == "user", then user, admin and root are authorized
 * If role == "admin", then admin and root are authorized
 * If role == "root", then only root are authorized
 * After checking, if he/she is not authorized, redirect to default path.
 *
 * @param {Component} WrappedComponent The component to be wrapped
 * @param {string} role The role. It could be ['user', 'admin', 'root']
 */
const requireRole = role => WrappedComponent => {
	class ComposedComponent extends React.Component {
		componentDidMount() {
			this.shouldNavigateAway();

			const { currentUser, clientProcessing, switch_client } = this.props;
			if (currentUser.role === 'root') {
				if (!switch_client && !clientProcessing) {
					let switchClient = localStorage.getItem('switchClient');
					if (switchClient) {
						let clientId = {};
						clientId['id'] = switchClient;
						this.props.loadClient(clientId).then(() => {
							if (this.props.errorMessage) {
								localStorage.removeItem('switchClient');
								this.props.removeClientError();
							} else {
								let { client } = this.props;
								if (client && !isEqual(client, this.props.switch_client)) {
									this.props.setSwitchClient(client);
								}
							}
						});
					}
				}
			}
		}

		componentDidUpdate() {
			this.shouldNavigateAway();
		}

		isAuthorized = () => {
			const { currentUser, isSignedIn } = this.props;
			if (!isSignedIn) {
				this.props.setAttemptedPath(this.props.currentPath);
				return false;
			}

			if (role === 'user') {
				// user, admin and root is allowed
				return true;
			}
			if (role === 'admin') {
				// admin and root is allowed
				if (currentUser.role === 'admin' || currentUser.role === 'root') {
					return true;
				}
			} else if (role === 'root') {
				// only root is allowed
				if (currentUser.role === 'root') {
					return true;
				}
			}

			return false;
		};

		shouldNavigateAway = () => {
			if (!this.isAuthorized()) {
				this.props.history.replace(this.props.defaultPath);
			} else {
			}
		};

		render() {
			return <WrappedComponent {...this.props} />;
		}
	}

	const mapStateToProps = state => ({
		currentUser: getCurrentUser(state),
		isSignedIn: getIsSignedIn(state),
		defaultPath: getDefaultPath(state),
		currentPath: state.router.location.pathname,
		switch_client: getSwitchClient(state),
		client: getClient(state),
		errorMessage: getClientsError(state),
		clientProcessing: getClientsProcessing(state)
	});

	return connect(mapStateToProps, {
		setAttemptedPath,
		loadClient,
		setSwitchClient,
		removeClientError
	})(withRouter(ComposedComponent));
};

export default requireRole;
