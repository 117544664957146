import { createSelector } from 'reselect';

export const getAuthState = (state) => state.auth;

export const getProcessing = createSelector(getAuthState, (auth) => auth.processing);

export const getProcessed = createSelector(getAuthState, (auth) => auth.processed);

export const getSignedInWith = createSelector(getAuthState, (auth) => auth.signedInWith);
export const getUserClient = createSelector(getAuthState, (auth) => auth.userClient);

export const getError = createSelector(getAuthState, (auth) => auth.error);
export const getToken = createSelector(getAuthState, (auth) => auth.token);
export const getCurrentUser = createSelector(
	getAuthState,
	(state) => state.switchClient.switch_client,
	(auth, switchClient) => {
		const currentUser = auth.user;
		/**
		 * Returns the client id attached to the signed in user.
		 */
		currentUser.getClientId = () => {
			if (currentUser.role === 'root' && switchClient) {
				return switchClient._id;
			} else if (currentUser.role === 'admin' || currentUser.role === 'user') {
				return currentUser.cid;
			}
			return null;
		};

		return currentUser;
	}
);

export const getIsSignedIn = createSelector(getAuthState, (auth) => auth.token !== null);

export const getDefaultPath = createSelector(getAuthState, (auth) => auth.defaultPath);

export const getAttemptPath = createSelector(getAuthState, (auth) => auth.attemptedPath);
