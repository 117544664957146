import moment from 'moment';
export const required = (value) => (!value || (value && value.trim && value.trim() === '') ? 'Required' : undefined);

export const arrayrequired = (value) => (!value || (value && value.length === 0) ? 'Required' : undefined);

export const svgrequired = (value) => (value && value.file && value.file.type !== 'image/svg+xml' ? 'This file is not svg file' : undefined);

export const objectrequired = (value) => (!value ? 'Required' : undefined);

export const afterdate = (start, end, msg = 'End must be after start') => {
	if (!end || !start) return undefined;
	return end.valueOf() < start.valueOf() ? msg : undefined;
};

export const least2day = (start, end, msg = 'End must be after start') => {
	if (!end || !start) return undefined;
	return moment(end).diff(start) <= 1 ? msg : undefined;
};

function isLeapYear(year) {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const inYear = (start, end, msg = 'The time period must be within or equal to one year') => {
	if (!end || !start) return undefined;
	let oneYear = (isLeapYear(start.getFullYear()) ? 366 : 365) * 24 * 60 * 60 * 1000; // Số mili giây trong một năm

	return Math.abs(end.valueOf() - start.valueOf()) > oneYear ? msg : undefined;
};

export const email = (value) => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? 'Invalid email address' : undefined);

export const minLength = (min) => (value) => (value && value.length < min ? `Must be at least ${min} characters ` : undefined);

export const maxLength = (max) => (value) => (value && value.length > max ? `Max length is ${max} characters ` : undefined);

export const minValue = (min) => (value) => (value && value < min ? `Must be bigger than at least ${min} ` : undefined);

export const isUrl = (value) => /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(value);

export const passwordsMustMatch = (value, allValues) => (value !== allValues.password ? 'Passwords do not match' : undefined);
export const isValidDomain = (domain) => {
	// Define a regular expression for validating a domain
	const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	// Test the provided domain against the regular expression
	return !domain ? undefined : domainRegex.test(domain) || isUrl(domain) ? undefined : 'Invalid domain';
};
