import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import PublishModal from '../../PublishModal';
import { Button } from 'reactstrap';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

const logo = `${process.env.PUBLIC_URL}/img/envent-logo.png`;

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired,
		user: UserProps.isRequired
	};

	constructor() {
		super();
		this.state = {
			showPublishModal: false
		};
	}

	onPublishClick = () => {
		this.setState({ showPublishModal: true });
	};

	onPublishModalClose = () => {
		this.setState({ showPublishModal: false });
	};

	render() {
		const { changeMobileSidebarVisibility, changeSidebarVisibility, user } = this.props;
		const { showPublishModal } = this.state;

		return (
			<div className="topbar">
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} changeSidebarVisibility={changeSidebarVisibility} />
						<Link className="topbar__logo" to="/dashboard">
							<img src={logo} />
							{/* <div className="topbar__logo_text">Envent</div> */}
						</Link>
					</div>
					<div className="topbar__right">
						{/*<TopbarNotification />*/}
						<Button color="primary" onClick={this.onPublishClick}>
							Publish
						</Button>
						<TopbarProfile user={user} />
					</div>
				</div>

				{showPublishModal && <PublishModal onCloseClick={this.onPublishModalClose} />}
			</div>
		);
	}
}

export default Topbar;
