import { createSelector } from 'reselect';

export const getClientState = (state) => state.client;

export const getClientsProcessing = createSelector(getClientState, (client) => client.processing);

export const getClientsProcessed = createSelector(getClientState, (client) => client.processed);

export const getClientsError = createSelector(getClientState, (client) => client.error);

export const getClients = createSelector(getClientState, (client) => client.clients);

export const getClient = createSelector(getClientState, (client) => client.client);
export const getDashClient = createSelector(getClientState, (client) => client.dash_client);

export const getClientsDeleted = createSelector(getClientState, (client) => client.deleted);
