import { createSelector } from 'reselect';

export const getTenantState = (state) => state.tenant;

export const getTenantsProcessing = createSelector(getTenantState, (tenant) => tenant.processing);

export const getTenantsProcessed = createSelector(getTenantState, (tenant) => tenant.processed);

export const getTenantsError = createSelector(getTenantState, (tenant) => tenant.error);

export const getTenants = createSelector(getTenantState, (tenant) => tenant.tenants);

export const getTenant = createSelector(getTenantState, (tenant) => tenant.tenant);
