import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import browserImageSize from 'browser-image-size';

const VIDEOS_EXT = [
	'webm',
	'mkv',
	'flv',
	'vob',
	'ogv',
	'ogg',
	'rrc',
	'gifv',
	'mng',
	'mov',
	'avi',
	'qt',
	'wmv',
	'yuv',
	'rm',
	'asf',
	'amv',
	'mp4',
	'm4p',
	'm4v',
	'mpg',
	'mp2',
	'mpeg',
	'mpe',
	'mpv',
	'm4v',
	'svi',
	'3gp',
	'3g2',
	'mxf',
	'roq',
	'nsv',
	'flv',
	'f4v',
	'f4p',
	'f4a',
	'f4b',
	'mod'
];
class DropZoneField extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		customHeight: PropTypes.bool,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string
				})
			)
		]).isRequired
	};

	static defaultProps = {
		customHeight: false
	};

	constructor() {
		super();
		this.state = {
			imageDims: {
				width: 0,
				height: 0
			}
		};
		this.onDrop = this.onDrop.bind(this);
	}

	async onDrop(files) {
		const { onChange } = this.props;

		const uploadFile = files[0];
		if (uploadFile && (uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png')) {
			const sizes = await browserImageSize(uploadFile);
			this.setState({ imageDims: sizes });
		}

		onChange(files);
	}

	removeFile(index, e) {
		const { onChange, value } = this.props;
		e.preventDefault();
		onChange(value.filter((val, i) => i !== index));
	}

	render() {
		const { value, customHeight, name, defaultImage } = this.props;
		const { imageDims, imageSrc } = this.state;

		const files = value;

		if (Array.isArray(value) && value.length && value[0] instanceof File && !imageSrc) {
			const reader = new FileReader();
			reader.onload = (e) => {
				// setImageSrc(e.target.result);
				this.setState({ imageSrc: e.target.result });
			};
			reader.readAsDataURL(value[0]);
		} else if ((!value || !value.length) && imageSrc) {
			this.setState({ imageSrc: null });
		}
		let alertString = ' Drop file here to upload';
		if (defaultImage) {
			alertString = ' Drop file here to replace';
		}
		let isVideo = /(?:\.([^.]+))?$/.exec(defaultImage);
		if (isVideo && VIDEOS_EXT.includes(isVideo[1])) isVideo = true;
		else isVideo = false;
		if (!defaultImage && files && files.length) {
			isVideo = /(?:\.([^.]+))?$/.exec(files[0].name);
			if (isVideo && VIDEOS_EXT.includes(isVideo[1])) isVideo = true;
			else isVideo = false;
		}

		return (
			<div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}>
				{defaultImage && !(files && Array.isArray(files) && files.length > 0) && <aside className="dropzone__img">{isVideo ? <video src={defaultImage} alt="drop-img" /> : <img src={defaultImage} alt="drop-img" />}</aside>}
				<Dropzone
					accept="image/gif, image/jpeg, image/png, video/mp4, video/avi"
					name={`${name} (Resolution: ${imageDims.width} x ${imageDims.height})`}
					multiple={false}
					onDrop={(fileToUpload) => {
						this.onDrop(fileToUpload);
					}}
				>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()} className="dropzone__input">
							{(!files || files.length === 0) && (
								<div className="dropzone__drop-here">
									<span className="lnr lnr-upload" />
									{alertString}
								</div>
							)}
							<input {...getInputProps()} />
						</div>
					)}
				</Dropzone>
				{files && Array.isArray(files) && files.length > 0 && (
					<aside className="dropzone__img">
						<img src={imageSrc || defaultImage} />
						<p className="dropzone__img-name">{files[0].name}</p>
						{imageDims.width !== { width: 0, height: 0 } && <p className="dropzone__res">{`(Resolution: ${imageDims.width}px x ${imageDims.height}px)`}</p>}
						<button className="dropzone__img-delete" type="button" onClick={(e) => this.removeFile(0, e)}>
							Remove
						</button>
					</aside>
				)}
			</div>
		);
	}
}

const renderDropZoneField = (props) => {
	const { input, customHeight, defaultImage, meta } = props;
	return (
		<div className="form__form-group-input-wrap">
			<DropZoneField {...input} defaultImage={defaultImage} customHeight={customHeight} />
			{meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
		</div>
	);
};

renderDropZoneField.propTypes = {
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}),
	input: PropTypes.shape({
		name: PropTypes.string,
		onChange: PropTypes.func
	}).isRequired,
	defaultImage: PropTypes.string,
	customHeight: PropTypes.bool
};

renderDropZoneField.defaultProps = {
	customHeight: false
};

export default renderDropZoneField;
