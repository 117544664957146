import { SET_WEBSOCKET_INSTANCE, SET_IS_SOCKET_CLOSED } from '../actions/types';

const initialState = {
	ws: null,
	// Determines whether or not the current socket connection is closed.
	isSocketClosed: false
};

const socketReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_WEBSOCKET_INSTANCE:
			return {
				...state,
				ws: action.payload,
				isSocketClosed: false
			};

		case SET_IS_SOCKET_CLOSED:
			return {
				...state,
				isSocketClosed: action.payload
			};

		default:
			return state;
	}
};

export default socketReducer;
