import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	metaTag: {},
	metaTags: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const metaTagReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				metaTag: {},
				metaTags: []
			};
		case actionTypes.LOAD_META_TAGS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.ADD_META_TAG:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				metaTag: {}
			};
		case actionTypes.LOAD_META_TAGS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				metaTags: action.payload.metaTags
			};
		case actionTypes.ADD_META_TAG_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				metaTag: { ...action.payload.metaTag },
				metaTags: action.payload.metaTags
			};
		case actionTypes.LOAD_META_TAGS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_META_TAG_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default metaTagReducer;
