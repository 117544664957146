import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import { Alert, Button } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Field, Form } from 'redux-form';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { Link } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';
const logo = `${process.env.PUBLIC_URL}/img/envent-logo.png`;

const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;
const ResetPasswordCard = ({ changeIsOpenModalFireBase }) => {
	return (
		<div className="account">
			<div className="account__wrapper login-form-container">
				<div className="account__card">
					<div className="account__head">
						<div className="account__logo-img">
							<img src={logo} alt="" />
						</div>
					</div>
					<ResetPasswordForm form="fromResetPassword" />
				</div>
			</div>
		</div>
	);
};

ResetPasswordCard.propTypes = {
	changeIsOpenModalFireBase: PropTypes.func.isRequired
};

export default connect(state => ({ themeName: state.theme.className }))(withTranslation('common')(ResetPasswordCard));
