import React from 'react';
import PropTypes from 'prop-types';
import { PropagateLoader } from 'react-spinners';

const Loading = ({ loading, loadNoBG }) => (
	<div className={`load${loading ? '' : ' loaded'} ${loadNoBG ? 'load-no-bg' : ''}`}>
		<div className="load__icon-wrap">
			<svg className="load__icon">
				<path id="loading__icon" fill="#f7923a" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
			</svg>
		</div>
	</div>
);

Loading.propTypes = {
	loading: PropTypes.bool,
	// Whether or not the loading spinner container should have a white background. False by default.
	loadNoBG: PropTypes.bool
};

Loading.defaultProps = {
	loading: false,
	loadNoBG: false
};

export const LoadingWithSpinner = ({ loading }) => {
	return (
		<div
			style={{
				position: 'absolute',
				left: 0,
				top: 0,
				display: 'flex',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<PropagateLoader
				color={'#F7923A'}
				loading={loading}
				cssOverride={{
					display: 'block',
					margin: '0 auto',
					borderColor: 'red'
				}}
				size={15}
			/>
		</div>
	);
};

export default Loading;
