import React, { PureComponent } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import { compose } from 'redux';
import { getProcessing, getError, getCurrentUser } from '../../../../redux/selectors';
import { unloadAuthPage, requestPasswordReset } from '../../../../redux/actions/authActions';
import { email, required } from '../../../../shared/components/login/formValidator';
import requireAnonymous from '../../../../hoc/requireAnonymous';

class ForgotPasswordForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		errorMessage: PropTypes.string,
		errorMsg: PropTypes.string,
		form: PropTypes.string.isRequired
	};

	static defaultProps = {
		errorMessage: '',
		errorMsg: ''
	};

	constructor() {
		super();
		this.state = {
			showPassword: false,
			success: false
		};

		this.showPassword = this.showPassword.bind(this);
	}

	showPassword(e) {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	}

	onSubmit = (formValues) => {
		formValues['tokenPurpose'] = 'resetPassword';
		return this.props.requestPasswordReset(formValues).then((res) => {
			console.log(res);
			if (this.props.errorMessage) {
			} else {
				this.setState({ success: true, successMessage: res.data.message });
				// this.props.history.push('/reset-password');
			}
		});
	};

	render() {
		const { handleSubmit, errorMessage, form, valid, pristine, submitting, error } = this.props;
		const { success, successMessage } = this.state;
		return (
			<Form className="form forgot-form" onSubmit={handleSubmit(this.onSubmit)}>
				<Alert color="info" isOpen={success}>
					{successMessage}
				</Alert>
				<Alert color="danger" isOpen={!!error || !!errorMessage}>
					{error}
					{errorMessage}
				</Alert>
				<div className="form__form-group">
					<span className="form__form-group-label">E-mail</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<AccountOutlineIcon />
						</div>
						<Field name="email" component="input" type="email" placeholder="E-mail" />
					</div>
				</div>
				<div className="form__form-group">
					{form === 'modal_forgot' ? (
						<Button className="account__btn" submit="true" type="submit" disabled={pristine || submitting || !valid} color="primary">
							Recover Password
						</Button>
					) : (
						<Link className="account__btn btn btn-primary" to="/dashboard">
							Submit
						</Link>
					)}
				</div>
			</Form>
		);
	}

	componentWillUnmount() {
		this.props.unloadAuthPage();
	}
}

const maptStateToProps = (state) => {
	return {
		isProcessing: getProcessing(state),
		errorMessage: getError(state),
		currentUser: getCurrentUser(state)
	};
};

const validate = (values) => {
	const errors = {};
	errors.email = required(values.email) || email(values.email);
	return errors;
};

export default compose(
	requireAnonymous(),
	connect(maptStateToProps, {
		requestPasswordReset,
		unloadAuthPage
	}),
	reduxForm({ form: 'forgotPassword', validate })
)(ForgotPasswordForm);
