import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarClientSearch from './SidebarClientSearch';
import SidebarCategory from './SidebarCategory';
import { replace } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { getClient, getClients, getClientsError, getCurrentUser, getMap, getMaps, getSignedInWith, getSwitchClient, getUserClient, getUsers, getCurrentMap } from '../../../redux/selectors';
import { compose } from 'redux';
import requireRole from '../../../hoc/requireRole';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setSwitchClient, signOut } from '../../../redux/actions/authActions';
import { loadClient, removeClientError } from '../../../redux/actions/clientsActions';
import { loadClientMaps, loadMap, loadMaps, updateMap, setCurrentMap } from '../../../redux/actions/map.actions';

class SidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			clients: [],
			activeMap: {},
			userClient: this.props.userClient,
			currentUser: this.props.currentUser
		};
	}

	componentDidMount() {
		const { currentUser, switch_client } = this.props;

		if (currentUser.role === 'root') {
			if (!switch_client) {
				let switchClient = localStorage.getItem('switchClient');
				if (switchClient) {
					let clientId = {};
					clientId['id'] = switchClient;
					this.props.loadClient(clientId).then(() => {
						if (this.props.errorMessage) {
							localStorage.removeItem('switchClient');
							this.props.removeClientError();
						} else {
							let { client } = this.props;
							if (client) {
								this.setState({ userClient: client });
								this.props.setSwitchClient(client);
							}
						}
					});
				}
			}
		}
		this.loadMaps(switch_client);
		if (this.props.maps) {
			let maps = this.props.maps;
			if (maps && maps.length > 0) {
				let activeMaps = maps.find((item) => item.active);
				if (activeMaps) {
					this.props.setCurrentMap(activeMaps._id);
					// this.setState({
					// 	activeMap: activeMaps,
					// });
				}
			}
		}
	}

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.switch_client && (!this.props.switch_client || (this.props.switch_client && this.props.switch_client._id !== nextProps.switch_client._id) || this.state.userClient._id !== nextProps.switch_client._id)) {
			this.setState({
				userClient: nextProps.switch_client,
				activeMap: {}
			});
			this.forceUpdate();
			this.loadMaps(nextProps.switch_client);
		}

		if (nextProps.currentMapId != this.props.currentMapId) {
			let maps = this.props.maps;
			let activeMaps = maps.find((m) => m._id == nextProps.currentMapId) || {};
			this.setState({
				activeMap: activeMaps
			});
		}
		// if (nextProps.maps && nextProps.maps !== this.props.maps) {
		//   let maps = nextProps.maps;
		//   if (maps && maps.length > 0) {
		//     let activeMaps = maps.filter(item => item.active);
		//     if (activeMaps && activeMaps.length > 0) {
		//       this.setState({
		//         activeMap: activeMaps[0]
		//       });
		//     }
		//   }
		// }
	}

	loadMaps = (switch_client) => {
		const { userClient, currentUser } = this.props;

		if (currentUser) {
			let client;
			if (currentUser.role === 'root') {
				if (switch_client) {
					client = switch_client;
				}
			} else {
				if (userClient) {
					client = userClient;
				}
				// this.props.loadMaps().then(() => {
				//   if (this.props.errorMessage) {
				//   } else {
				//     let { maps } = this.props;
				//     if (maps && maps.length > 0) {
				//       let activeMaps = maps.filter(item => item.active);
				//       if (activeMaps && activeMaps.length > 0) {
				//         this.setState({
				//           activeMap: activeMaps[0]
				//         });
				//       }
				//     }
				//   }
				// });
			}
			if (client) {
				this.props.loadClientMaps(client).then(() => {
					if (this.props.errorMessage) {
					} else {
						let { maps } = this.props;
						if (maps && maps.length > 0) {
							let activeMaps = maps.find((item) => item.active && item.cid === client._id);
							if (activeMaps) {
								this.props.setCurrentMap(activeMaps._id);
							}
						}
					}
				});
			}
		}
	};

	getWithDisableModulePermissions = (moduleIds) => {
		const { currentUser, userClient } = this.state;
		if (currentUser) {
			if (currentUser.disableModule) {
				let module = currentUser.disableModule.find((item) => item.value === moduleIds || item.value.charAt(0) === moduleIds);
				if (module) {
					return false;
				}
			}
		}
		if (userClient) {
			if (userClient.module) {
				let module = userClient.module.find((item) => item.value === moduleIds || item.value.charAt(0) === moduleIds);
				if (module) {
					return true;
				}
			}
		}
		return false;
	};

	getModulePermissions = (moduleIds) => {
		const { currentUser, userClient } = this.state;
		if (currentUser) {
			if (currentUser.disableModule) {
				let module = currentUser.disableModule.find((item) => item.value === moduleIds);
				if (module) {
					return false;
				}
			}
		}
		if (userClient) {
			if (userClient.module) {
				let module = userClient.module.find((item) => item.value === moduleIds || item.value.charAt(0) === moduleIds);
				if (module) {
					return true;
				}
			}
		}
		return false;
	};

	render() {
		const { currentUser, signOut } = this.props;
		let role = 'user';
		if (currentUser) {
			role = currentUser.role;
		}

		let { activeMap } = this.state;

		let showClientAdminNav = false;
		let cid = currentUser.cid;
		const { userClient } = this.state;
		if (currentUser.role === 'root') {
			cid = userClient._id;
			showClientAdminNav = true;
		}

		if (currentUser.role === 'admin' && userClient && userClient.module) {
			showClientAdminNav = true;
		}

		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					<SidebarLink title="Dashboard" route="/dashboard" icon="home" />
				</ul>
				{role === 'root' && (
					<ul className="sidebar__block">
						<SidebarCategory title="Envent Admin" icon="user">
							<SidebarCategory title="Change Client">
								<SidebarClientSearch {...this.state} />
							</SidebarCategory>
							<SidebarCategory title="Manage Clients">
								<SidebarLink title="Clients" route="/client/list" />
								<SidebarLink title="New Client" route="/client/new" />
							</SidebarCategory>
							<SidebarCategory title="Manage Users">
								<SidebarLink title="Users" route="/user/list/root" />
								<SidebarLink title="New User" route="/user/new/root" />
							</SidebarCategory>

							<SidebarCategory title="Manage Devices">
								<SidebarLink title="Devices" route="/device/list" />
								<SidebarLink title="New Device" route="/device/new" />
							</SidebarCategory>
						</SidebarCategory>
					</ul>
				)}

				{showClientAdminNav && (
					<ul className="sidebar__block">
						<SidebarCategory title="Client Admin" icon="user">
							<SidebarCategory title="Manage Users">
								<SidebarLink title="Users" route="/user/list/client" />
								<SidebarLink title="New User" route="/user/new/client" />
							</SidebarCategory>
						</SidebarCategory>
					</ul>
				)}

				{this.getModulePermissions('2') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Wayfinder" icon="map">
							{(this.getModulePermissions('24') || this.getModulePermissions('25')) && (
								<SidebarCategory title="Destinations">
									{this.getModulePermissions('24') && <SidebarLink title="View Destinations" route="/wayfinder/tenant/view" />}

									{this.getModulePermissions('25') && <SidebarLink title="New Destination" route="/wayfinder/tenant/create" />}
								</SidebarCategory>
							)}
							{this.getModulePermissions('21') && <SidebarLink title="Categories" route="/wayfinder/category" />}

							{this.getModulePermissions('22') && activeMap._id && <SidebarLink title="Map Viewer" route={`/wayfinder/viewer/${activeMap._id}`} />}
							{this.getModulePermissions('23') && activeMap._id && <SidebarLink title="Map Editor" route={`/wayfinder/editor/${activeMap._id}`} />}

							{/*{this.getModulePermissions('27') && (*/}
							{/*    <SidebarLink title="Quick Destinations" route="/wayfinder/quick" />*/}
							{/*)}*/}
							<SidebarLink title="Quick Destinations" route="/wayfinder/quick" />
						</SidebarCategory>
					</ul>
				)}

				{this.getModulePermissions('1') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Advertising" icon="film-play">
							<SidebarLink title="View" route="/advertising/view" />
							<SidebarLink title="Create" route="/advertising/create" />
						</SidebarCategory>
					</ul>
				)}

				{userClient && userClient._id == '61247b12f4e679047ab1b8b9' && (
					<ul className="sidebar__block">
						<SidebarCategory title="Advertising NEW" icon="film-play">
							<SidebarLink title="View" route="/advertising-new/view" />
							<SidebarLink title="Create" route="/advertising-new/create" />
						</SidebarCategory>
					</ul>
				)}

				{this.getModulePermissions('8') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Information" icon="laptop">
							<SidebarLink title="Create" route="/info/create" />
							<SidebarLink title="View" route="/info/view" />
						</SidebarCategory>
					</ul>
				)}

				{/* {this.getModulePermissions('9') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Office Information" icon="laptop">
							<SidebarLink title="View" route="/office/view" />
							<SidebarLink title="Create" route="/office/create" />
						</SidebarCategory>
					</ul>
				)} */}

				{this.getModulePermissions('3') && (
					<>
						<ul className="sidebar__block">
							<SidebarCategory title="Events" icon="gift">
								<SidebarLink title="View" route="/events/view" />
								<SidebarLink title="Create" route="/events/create" />
							</SidebarCategory>
						</ul>

						<ul className="sidebar__block">
							<SidebarCategory title="Surveys" icon="briefcase">
								<SidebarLink title="View" route="/surveys/view" />
								<SidebarLink title="Create" route="/surveys/create" />
							</SidebarCategory>
						</ul>
					</>
				)}

				{this.getModulePermissions('5') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Billing" icon="store">
							<SidebarLink title="New Invoices" route="/advertising/view" />
							<SidebarLink title="Paid Invoices" route="/advertising/view" />
						</SidebarCategory>
					</ul>
				)}

				{this.getModulePermissions('6') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Support" icon="bug">
							<SidebarLink title="Create Ticket" route="/view-ads" />
							<SidebarLink title="View Tickets" route="/create-ads" />
						</SidebarCategory>
					</ul>
				)}

				{this.getModulePermissions('7') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Static Tenant Directory" icon="map">
							<SidebarLink title="View Tenants" route="/static-directory/tenant/view" />
							<SidebarLink title="New Tenant" route="/static-directory/tenant/create" />
						</SidebarCategory>
					</ul>
				)}

				{this.getModulePermissions('4') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Report" icon="chart-bars">
							<SidebarLink title="Generate Report" route="/report" />
							<SidebarLink title="Custom Report" route="/report-custom" />
						</SidebarCategory>
					</ul>
				)}

				<ul className="sidebar__block">
					<SidebarCategory title="Meridian" icon="chart-bars">
						<SidebarLink title="Meridian CMS" route="/meridian" />
					</SidebarCategory>
				</ul>

				{this.getModulePermissions('10') && (
					<ul className="sidebar__block">
						<SidebarCategory title="Downer Report" icon="chart-bars">
							<SidebarLink title="Downer Report" route="/downer/report" />
							<SidebarLink title="Statistical Downer Report" route="/downer/statistical" />
						</SidebarCategory>
					</ul>
				)}
			</div>
		);
	}
}

const sidebarStateToProps = (state) => ({
	themeName: state.theme.className,
	rtl: state.rtl,
	currentMapId: getCurrentMap(state),
	currentUser: getCurrentUser(state),
	signedInWith: getSignedInWith(state),
	userClient: getUserClient(state),
	switch_client: getSwitchClient(state),
	errorMessage: getClientsError(state),
	client: getClient(state),
	maps: getMaps(state),
	map: getMap(state)
});

export default withRouter(
	compose(
		requireRole('user'),
		withTranslation('common'),
		connect(sidebarStateToProps, {
			loadMaps,
			loadMap,
			loadClientMaps,
			signOut,
			loadClient,
			setSwitchClient,
			setCurrentMap,
			removeClientError
		})
	)(SidebarContent)
);
