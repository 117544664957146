import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	information: {},
	informations: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const informationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				information: {},
				informations: []
			};
		case actionTypes.LOAD_INFORMATIONS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				informations: []
			};
		case actionTypes.LOAD_INFORMATION:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				information: {}
			};
		case actionTypes.ADD_INFORMATION:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				information: {}
			};
		case actionTypes.UPDATE_INFORMATION:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.DELETE_INFORMATION:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_INFORMATIONS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				informations: action.payload.infos,
				defaultPath: '/dashboard'
			};
		case actionTypes.LOAD_INFORMATION_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				information: { ...action.payload.information }
			};
		case actionTypes.ADD_INFORMATION_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				information: { ...action.payload.information }
			};
		case actionTypes.UPDATE_INFORMATION_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				information: { ...action.payload.information }
			};
		case actionTypes.DELETE_INFORMATION_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				informations: action.payload.infos
			};
		case actionTypes.LOAD_INFORMATION_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_INFORMATION_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_INFORMATION_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.DELETE_INFORMATION_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default informationReducer;
