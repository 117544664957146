import React, { Component } from 'react';
import SidebarLink from './SidebarLink';
import { getClients, getClientsDeleted, getCurrentUser, getSignedInWith, getSwitchClient, getUserClient } from '../../../redux/selectors';
import { compose } from 'redux';
import requireRole from '../../../hoc/requireRole';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signOut } from '../../../redux/actions/authActions';
import { loadClients, resetClientDeleteFlag } from '../../../redux/actions/clientsActions';
import { Bar, Cell } from 'recharts';

class ClientSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filtered: [],
			allClients: []
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.setState({
			filtered: this.props.recentClients
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.allClients !== nextProps.allClients) {
			this.setState({
				allClients: nextProps.allClients
			});
		}

		if (this.state.filtered !== nextProps.recentClients) {
			this.setState({
				filtered: nextProps.recentClients
			});
		}
	}

	handleChange(e) {
		// Variable to hold the original version of the list
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];

		// If the search bar isn't empty
		if (e.target.value !== '') {
			// Assign the original list to currentList
			currentList = this.props.allClients;

			newList = currentList.filter((client) => {
				// change current item to lowercase
				const lc = client.clientName.toLowerCase();
				const filter = e.target.value.toLowerCase();
				return lc.includes(filter) ? client : undefined;
			});
		} else {
			newList = this.props.recentClients;
		}

		// Set the filtered state based on what our rules added to newList
		this.setState({
			filtered: newList
		});
	}

	render() {
		const { filtered } = this.state;
		let { switch_client } = this.props;
		return (
			<div className="sidebar__clientsearch">
				<input type="text" className="sidebar__link" onChange={this.handleChange} placeholder="Search..." />
				{filtered.map((client, index) =>
					switch_client && switch_client._id === client._id ? (
						<SidebarLink title={client.clientName} route={'/client_dash/' + client._id} key={client._id} selected={true} />
					) : (
						<SidebarLink title={client.clientName} route={'/client_dash/' + client._id} key={client._id} selected={false} />
					)
				)}
			</div>
		);
	}
}

class SidebarClientSearch extends Component {
	static defaultProps = {
		icon: '',
		isNew: false
	};

	constructor() {
		super();

		this.state = {
			collapse: false
		};

		this.state = {
			recentClients: [],
			allClients: []
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.switch_client !== nextProps.switch_client) {
			if (nextProps.switch_client) {
				this.loadClients();
			}
		}

		if (this.props.deleted !== nextProps.deleted) {
			if (nextProps.deleted) {
				this.loadClients();
				this.props.resetClientDeleteFlag();
			}
		}
		if (this.props.clients !== nextProps.clients) {
			if (nextProps.clients != null) {
				var clients = nextProps.clients;
				let recentClients = [];
				if (nextProps.switch_client) {
					recentClients.unshift(nextProps.switch_client);
				}

				if (clients.length > 3) {
					if (nextProps.switch_client) {
						for (let index = 0; index < clients.length; index++) {
							if (clients[index]._id !== nextProps.switch_client._id) {
								recentClients.push(clients[index]);
							}
							if (recentClients.length >= 3) {
								break;
							}
						}
					} else {
						recentClients = clients.slice(0, 3);
					}
				} else {
					recentClients = clients;
				}

				this.setState({
					allClients: clients,
					recentClients: recentClients
				});
			}
		}
	}

	loadClients = () => {
		const { userClient, currentUser, clients } = this.props;

		if (currentUser.role !== 'root') {
			this.setState({
				allClients: [userClient],
				recentClients: [userClient]
			});
			return;
		}
		if (!clients || (clients && clients.length === 0)) {
			this.props.loadClients().then(() => {
				if (this.props.errorMessage) {
				} else {
					if (this.props.clients != null) {
						var clients = this.props.clients;
						let recentClients = [];
						if (clients.length > 3) {
							recentClients = clients.slice(0, 3);
						} else {
							recentClients = clients;
						}
						this.setState({
							allClients: clients,
							recentClients: recentClients
						});
					}
				}
			});
		}
	};

	componentDidMount() {
		this.loadClients();
		if (this.props.clients) {
			var clients = this.props.clients;
			const { switch_client } = this.props;

			let recentClients = [];
			if (switch_client) {
				recentClients.unshift(switch_client);
			}
			if (clients.length > 3) {
				if (switch_client) {
					for (let index = 0; index < clients.length; index++) {
						if (clients[index]._id !== switch_client._id) {
							recentClients.push(clients[index]);
						}
						if (recentClients.length >= 3) {
							break;
						}
					}
				} else {
					recentClients = clients.slice(0, 3);
				}
			} else {
				recentClients = clients;
			}

			this.setState({
				allClients: clients,
				recentClients: recentClients
			});
		}
	}

	render() {
		const { allClients, recentClients } = this.state;
		const { switch_client } = this.props;
		return (
			<div>
				<ClientSearch switch_client={switch_client} allClients={allClients} recentClients={recentClients} />
			</div>
		);
	}
}

const sidebarSearchStateToProps = (state) => ({
	clients: getClients(state),
	themeName: state.theme.className,
	userClient: getUserClient(state),
	currentUser: getCurrentUser(state),
	switch_client: getSwitchClient(state),
	deleted: getClientsDeleted(state)
});

export default compose(
	requireRole('root'),
	withTranslation('common'),
	connect(sidebarSearchStateToProps, {
		loadClients,
		resetClientDeleteFlag
	})
)(SidebarClientSearch);
