import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import AvTimerIcon from 'mdi-react/AvTimerIcon';
import classNames from 'classnames';
import moment from 'moment';

class TimePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		timeMode: PropTypes.bool.isRequired,
		theme: PropTypes.string,
		value: PropTypes.object
	};

	static defaultProps = {
		theme: 'theme-light'
	};

	constructor() {
		super();
		this.state = {
			open: false,
			startTime: null //char
		};
	}

	setOpen = ({ open }) => {
		this.setState({ open });
	};

	onPickerChange = (newTime) => {
		this.setState({ startTime: newTime });
		const { onChange } = this.props;
		onChange(newTime);
	};

	toggleOpen = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ open: !prevState.open }));
	};

	render() {
		const { name, timeMode, onChange, theme, value: initialValue } = this.props;
		const { open, startTime } = this.state;
		const btnClass = classNames({
			'form__form-group-button': true,
			active: open
		});
		// console.log(initialValue);
		let shouldReset = false;
		if (startTime && !initialValue) shouldReset = true;

		return (
			<div className="date-picker">
				<TimePicker
					open={open}
					onOpen={this.setOpen}
					onClose={this.setOpen}
					autoComplete="off"
					value={shouldReset ? '' : startTime || initialValue}
					name={name}
					onChange={this.onPickerChange}
					showSecond={false}
					popupClassName={theme === 'theme-dark' ? 'theme-dark' : 'theme-light'}
					use12Hours={timeMode}
				/>
			</div>
		);
	}
}

const renderTimePickerField = (props) => {
	const { input, timeMode, theme, meta } = props;
	// console.log(input);
	return (
		<div className="form__form-group-input-wrap">
			<TimePickerField {...input} timeMode={timeMode} theme={theme} />
			{meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
		</div>
	);
	// return <TimePickerField {...input} timeMode={timeMode} theme={theme} />;
};

renderTimePickerField.propTypes = {
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}),
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired,
	timeMode: PropTypes.bool,
	theme: PropTypes.string
};

renderTimePickerField.defaultProps = {
	timeMode: false,
	theme: null
};

export default renderTimePickerField;
