import { createSelector } from 'reselect';

export const getDeviceState = (state) => state.device;

export const getDevicesProcessing = createSelector(getDeviceState, (device) => device.processing);

export const getDevicesProcessed = createSelector(getDeviceState, (device) => device.processed);

export const getDevicesError = createSelector(getDeviceState, (device) => device.error);

export const getDevices = createSelector(getDeviceState, (device) => device.devices);

export const getDevice = createSelector(getDeviceState, (device) => device.device);
