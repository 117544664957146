import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' || true ? process.env.REACT_APP_API_URL : 'http://localhost:3001/';

const mernApi = axios.create({
	baseURL: API_URL + 'api'
});

mernApi.setAuthToken = (jwtToken) => {
	mernApi.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
};

export default mernApi;
