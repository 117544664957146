import { createSelector } from 'reselect';

export const getAdvertisementState = (state) => state.advertisement;

export const getAdvertisementsProcessing = createSelector(getAdvertisementState, (advertisement) => advertisement.processing);

export const getAdvertisementsProcessed = createSelector(getAdvertisementState, (advertisement) => advertisement.processed);

export const getAdvertisementsError = createSelector(getAdvertisementState, (advertisement) => advertisement.error);

export const getAdvertisements = createSelector(getAdvertisementState, (advertisement) => advertisement.advertisements);

export const getAdvertisement = createSelector(getAdvertisementState, (advertisement) => advertisement.advertisement);
