import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

class DatePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired
	};

	constructor() {
		super();
		this.state = {
			startDate: null
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		const { onChange } = this.props;
		console.log(date);
		if (!date) {
		}
		this.setState({
			startDate: date
		});
		onChange(date);
	}

	render() {
		const { startDate } = this.state;
		const { value: initialValue, disabled, dateFormat } = this.props;
		// console.log(initialValue);
		let shouldReset = false;
		if (startDate && !initialValue) shouldReset = true;

		return (
			<div className="date-picker">
				<DatePicker disabled={disabled} className="form__form-group-datepicker" selected={shouldReset ? '' : startDate || initialValue} onChange={this.handleChange} dateFormat={dateFormat || 'yyyy/MM/dd'} dropDownMode="select" popperPlacement="center" withPortal={isMobileOnly} />
			</div>
		);
	}
}

const renderDatePickerField = (props) => {
	const { input, meta, disabled, dateFormat } = props;
	return (
		<div className="form__form-group-input-wrap">
			<DatePickerField {...input} disabled={disabled} dateFormat={dateFormat} />
			{meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
		</div>
	);
};

renderDatePickerField.propTypes = {
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}),
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired
};

export default renderDatePickerField;
