import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	survey: {},
	surveys: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const surveyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				survey: {},
				surveys: []
			};
		case actionTypes.LOAD_SURVEYS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_SURVEY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				survey: {}
			};
		case actionTypes.ADD_SURVEY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				survey: {}
			};
		case actionTypes.UPDATE_SURVEY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.DELETE_SURVEY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_SURVEYS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				surveys: action.payload.surveys,
				defaultPath: '/dashboard'
			};
		case actionTypes.LOAD_SURVEY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				survey: { ...action.payload.survey }
			};
		case actionTypes.ADD_SURVEY_SUCCESS:
		case actionTypes.UPDATE_SURVEY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				survey: { ...action.payload.survey }
			};
		case actionTypes.DELETE_SURVEY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true
			};
		case actionTypes.LOAD_SURVEY_FAIL:
		case actionTypes.ADD_SURVEY_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_SURVEY_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.DELETE_SURVEY_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default surveyReducer;
