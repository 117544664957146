import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import ForgotPasswordForm from './ForgotPasswordForm';
const logo = `${process.env.PUBLIC_URL}/img/envent-logo.png`;

const ForgotPasswordCard = ({ changeIsOpenModalFireBase }) => {
	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<div className="account__logo-img">
							<img src={logo} alt="Envent Cloud" />
						</div>
					</div>
					{/* <div className="forgot-form">
						Please contact an Administrator
						<div className="form__form-group">
							<div className="account__forgot-password">
								<a href="/login">Login</a>
							</div>
						</div>
					</div> */}
					<ForgotPasswordForm form="modal_forgot" fieldUser="E-mail" typeFieldUser="email" />
				</div>
			</div>
		</div>
	);
};

ForgotPasswordCard.propTypes = {
	changeIsOpenModalFireBase: PropTypes.func.isRequired
};

export default connect((state) => ({ themeName: state.theme.className }))(withTranslation('common')(ForgotPasswordCard));
