import * as actionTypes from './types';
import { debounce, throttle } from 'lodash';

const loadClientsSuccess = (payload) => ({
	type: actionTypes.LOAD_CLIENTS_SUCCESS,
	payload
});

const loadClientsFail = (payload) => ({
	type: actionTypes.LOAD_CLIENTS_FAIL,
	payload
});

const _innerImplLoadClients = (dispatch, getState, { mernApi }) => {
	console.log('load list clients');
	dispatch({ type: actionTypes.LOAD_CLIENTS });
	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(loadClientsFail());
		return Promise.resolve();
	}
	mernApi.setAuthToken(authInfo.token);
	return mernApi.get('/client').then(
		(response) => {
			dispatch(loadClientsSuccess(response.data));
		},
		(err) => {
			console.log('casc');
			if (err.response && err.response.data) {
				dispatch(loadClientsFail(err.response.data.error));
			} else {
				dispatch(loadClientsFail('Server internal error'));
			}
		}
	);
};

const _innerLoadClients = throttle(_innerImplLoadClients, 2000, { leading: true, trailing: false });

export const loadClients = () => _innerLoadClients;

const loadClientSuccess = (payload) => ({
	type: actionTypes.LOAD_CLIENT_SUCCESS,
	payload
});

const loadClientFail = (payload) => ({
	type: actionTypes.LOAD_CLIENT_FAIL,
	payload
});

// export const loadClient = (formValues) => (dispatch, getState, { mernApi }) => {
// 	dispatch({ type: actionTypes.LOAD_CLIENT });
// 	// console.log('loadddddddddd', formValues);

// 	return mernApi.get(`/client/${formValues.id}`).then(
// 		(response) => {
// 			dispatch(loadClientSuccess(response.data));
// 		},
// 		(err) => {
// 			if (err.response && err.response.data) {
// 				dispatch(loadClientFail(err.response.data.error));
// 			} else {
// 				dispatch(loadClientFail('Server internal error'));
// 			}
// 		}
// 	);
// };

const _innerLoadClient = throttle(
	(formValues, dispatch, getState, { mernApi }) => {
		dispatch({ type: actionTypes.LOAD_CLIENT });
		console.log('load client detail', formValues);
		return mernApi.get(`/client/${formValues.id}`).then(
			(response) => {
				dispatch(loadClientSuccess(response.data));
			},
			(err) => {
				if (err.response && err.response.data) {
					dispatch(loadClientFail(err.response.data.error));
				} else {
					dispatch(loadClientFail('Server internal error'));
				}
			}
		);
	},
	1000,
	{ leading: true, trailing: false }
);
export const loadClient = (formValues) => (dispatch, getState, { mernApi }) => _innerLoadClient(formValues, dispatch, getState, { mernApi });

export const removeClientError = () => (dispatch, getState, { mernApi }) => {
	dispatch(loadClientFail(''));
};

export const resetClientDeleteFlag = () => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.DELETE_CLIENT });
};

const loadDashClientSuccess = (payload) => ({
	type: actionTypes.LOAD_DASH_CLIENT_SUCCESS,
	payload
});

const loadDashClientFail = (payload) => ({
	type: actionTypes.LOAD_DASH_CLIENT_FAIL,
	payload
});

export const getDashboardClient = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.LOAD_DASH_CLIENT });
	return mernApi.post(`/client/total`, formValues).then(
		(response) => {
			dispatch(loadDashClientSuccess(response.data));
		},
		(err) => {
			if (err.response && err.response.data) {
				dispatch(loadDashClientFail(err.response.data.error));
			} else {
				dispatch(loadDashClientFail('Server internal error'));
			}
		}
	);
};

const addClientSuccess = (payload) => ({
	type: actionTypes.ADD_CLIENT_SUCCESS,
	payload
});

const addClientFail = (payload) => ({
	type: actionTypes.ADD_CLIENT_FAIL,
	payload
});

export const addClient = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.ADD_CLIENT });

	const authInfo = JSON.parse(localStorage.getItem('authInfo'));
	const now = Math.floor(Date.now() / 1000);
	if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
		dispatch(addClientFail());
		return Promise.resolve();
	}
	// mernApi.setAuthToken(authInfo.token);

	const formData = new FormData();
	formData.append('clientName', formValues.clientName);
	formData.append('module', JSON.stringify(formValues.module));

	if (formValues.files && formValues.files.length > 0) {
		formData.append('image', formValues.files[0]);
	} else {
		dispatch(addClientFail('Client Header Image is empty'));
		return;
	}

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};
	return mernApi.post('/client/addClient', formData, config).then(
		(response) => {
			dispatch(addClientSuccess(response.data));
		},
		(err) => {
			if (err.response && err.response.data) {
				dispatch(addClientFail(err.response.data.error));
			} else {
				dispatch(addClientFail('Server internal error'));
			}
		}
	);
};

const updateClientSuccess = (payload) => ({
	type: actionTypes.UPDATE_CLIENT_SUCCESS,
	payload
});

const updateClientFail = (payload) => ({
	type: actionTypes.UPDATE_CLIENT_FAIL,
	payload
});

const setSwitchClientSucess = (payload) => ({
	type: actionTypes.SET_SWITCH_CLIENT,
	payload
});

export const updateClient = (client) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.UPDATE_CLIENT });

	const formData = new FormData();
	formData.append('clientName', client.clientName);
	formData.append('module', JSON.stringify(client.module));

	if (client.files && client.files.length > 0) {
		formData.append('image', client.files[0]);
	}

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};

	return mernApi.put(`/client/${client._id}`, formData, config).then(
		(response) => {
			dispatch(updateClientSuccess(response.data));
			dispatch(setSwitchClientSucess(response.data.client));
		},
		(err) => {
			if (err.response && err.response.data) {
				dispatch(updateClientFail(err.response.data.error));
			} else {
				dispatch(updateClientFail('Server internal error'));
			}
		}
	);
};

const deleteClientSuccess = (payload) => ({
	type: actionTypes.DELETE_CLIENTS_SUCCESS,
	payload
});

const deleteClientFail = (payload) => ({
	type: actionTypes.DELETE_CLIENTS_FAIL,
	payload
});

export const deleteClients = (seletedClients) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.DELETE_CLIENT });
	const postParam = { ids: seletedClients };
	return mernApi.post(`/client/delete`, postParam).then(
		(response) => {
			console.log('delete client deleteClients', response.data);
			dispatch(deleteClientSuccess(response.data));
		},
		(err) => {
			if (err.response && err.response.data) {
				dispatch(deleteClientFail(err.response.data.error));
			} else {
				dispatch(deleteClientFail('Server internal error'));
			}
		}
	);
};
