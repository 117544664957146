import * as actionTypes from './types';

const publishSuccess = (payload) => ({
	type: actionTypes.PUBLISH_SUCCESS,
	payload
});

const publishFail = (payload) => ({
	type: actionTypes.PUBLISH_FAIL,
	payload
});

export const publishUpdates =
	({ clientId, userId }) =>
	(dispatch, getState, { mernApi }) => {
		dispatch({ type: actionTypes.PUBLISH_UPDATES });

		return mernApi.post(`/publish/${clientId}`, { userId }).then(
			(response) => {
				dispatch(publishSuccess(response.data));
			},
			(err) => {
				if (err.response && err.response.data) {
					dispatch(publishFail(err.response.data.error));
				} else {
					dispatch(publishFail('Server error'));
				}
			}
		);
	};
