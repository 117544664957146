import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	device_status: [],
	processing: false,
	processed: false,
	error: null,
	change: '',
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const deviceStatusReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_DEVICE_STATUS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				change: ''
			};
		case actionTypes.LOAD_DEVICE_STATUS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				device_status: action.payload,
				defaultPath: '/dashboard'
			};
		case actionTypes.LOAD_DEVICE_STATUS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.DEVICE_STATUS_CHANGE:
			return {
				...state,
				processing: false,
				processed: true,
				change: 'change'
			};
		default:
			return state;
	}
};

export default deviceStatusReducer;
