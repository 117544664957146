import * as actionTypes from '../actions/types';

const initialState = {
	updateDetails: [],
	processing: false,
	processed: false,
	error: null
};

const publishReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PUBLISH_UPDATES:
			return {
				...state,
				error: null,
				processing: true,
				updateDetails: []
			};

		case actionTypes.PUBLISH_SUCCESS:
			return {
				...state,
				error: null,
				processing: false,
				processed: true,
				updateDetails: action.payload.updateDetails
			};

		case actionTypes.PUBLISH_FAIL:
			return {
				...state,
				error: action.payload,
				processing: false,
				processed: true
			};

		default:
			return state;
	}
};

export default publishReducer;
