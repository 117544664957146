import { createSelector } from 'reselect';

export const getQuickState = (state) => state.quick;

export const getQuickProcessing = createSelector(getQuickState, (quick) => quick.processing);

export const getQuickProcessed = createSelector(getQuickState, (quick) => quick.processed);

export const getQuickError = createSelector(getQuickState, (quick) => quick.error);

export const getQuicks = createSelector(getQuickState, (quick) => quick.quicks);

export const getQuick = createSelector(getQuickState, (quick) => quick.quick);
