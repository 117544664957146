import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import themeReducer from './themeReducer';
import rtlReducer from './rtlReducer';
import sidebarReducer from './sidebarReducer';
import cryptoTableReducer from './cryptoTableReducer';
import newOrderTableReducer from './newOrderTableReducer';
import customizerReducer from './customizerReducer';
import todoReducer from './todoReducer';
import authReducer from './authReducer';
import mapReducer from './map.reducer';
import uploadReducer from './upload.reducer';
import clientReducer from './clients.reducer';
import advertisementReducer from './advertisements.reducer';
import usersReducer from './users.reducer';
import devicesReducer from './devices.reducer';
import deviceStatusReducer from './deviceStatus.reducer';
import switchClientReducer from './switchClientReducer';
import categoryReducer from './category.reducer';
import metaTagReducer from './metaTag.reducer';
import staticCategoryReducer from './staticCategory.reducer';
import tenantReducer from './tenant.reducer';

import staticTenantReducer from './staticTenant.reducer';
import eventReducer from './events.reducer';
import surveyReducer from './survey.reducer';
import publishReducer from './publish.reducer';
import usageLogsReducer from './usageLogs.reducer';
import reportsReducer from './reports.reducer';
import quickReducer from './quick.reducer';
import informationReducer from './information.reducer';
import socketReducer from './socketReducer';
import downerReducer from './downer.reports.reducer';

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		form: formReducer,
		auth: authReducer,
		map: mapReducer,
		upload: uploadReducer,
		theme: themeReducer,
		rtl: rtlReducer,
		sidebar: sidebarReducer,
		cryptoTable: cryptoTableReducer,
		newOrder: newOrderTableReducer,
		customizer: customizerReducer,
		todos: todoReducer,
		user: authReducer,
		client: clientReducer,
		advertisement: advertisementReducer,
		event: eventReducer,
		account: usersReducer,
		device: devicesReducer,
		deviceStatus: deviceStatusReducer,
		switchClient: switchClientReducer,
		category: categoryReducer,
		staticCategory: staticCategoryReducer,
		metaTag: metaTagReducer,
		tenant: tenantReducer,
		staticTenant: staticTenantReducer,
		survey: surveyReducer,
		publish: publishReducer,
		logs: usageLogsReducer,
		report: reportsReducer,
		quick: quickReducer,
		info: informationReducer,
		socket: socketReducer,
		downer: downerReducer
	});

export default createRootReducer;
