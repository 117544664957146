import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

class DateTimePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
		onlyMonthYear: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			startDate: null
		};
	}

	handleChange = (date) => {
		const { onChange } = this.props;
		this.setState({
			startDate: date
		});
		onChange(date);
	};

	render() {
		const { startDate } = this.state;
		const { value, placeholder, onlyMonthYear, showTimeSelect, dateFormat, disabled } = this.props;
		let shouldReset = false;
		if (startDate && !value) shouldReset = true;
		console.log('placeholder', placeholder, value);
		let op = {
			timeFormat: 'HH:mm',
			dateFormat: dateFormat || 'MMMM d, yyyy h:mm aa',
			showMonthYearPicker: onlyMonthYear,
			showTimeSelect,
			value
		};
		if (onlyMonthYear) {
			delete op.timeFormat;
			delete op.showTimeSelect;
			op.dateFormat = dateFormat || 'MMMM yyyy';
		}
		return (
			<div className="date-picker">
				<DatePicker disabled={disabled} placeholderText={placeholder} className="form__form-group-datepicker" selected={shouldReset ? '' : startDate} onChange={this.handleChange} dropDownMode="select" withPortal={isMobileOnly} {...op} />
			</div>
		);
	}
}

const renderDateTimePickerField = (props) => {
	const { input, meta, placeholder, onlyMonthYear, showTimeSelect, dateFormat, disabled, value } = props;
	let showTime = showTimeSelect == undefined ? true : showTimeSelect;
	return (
		<div className="form__form-group-input-wrap">
			<DateTimePickerField {...input} placeholder={placeholder} onlyMonthYear={!!onlyMonthYear} showTimeSelect={showTime} dateFormat={dateFormat} disabled={disabled} />
			{meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
		</div>
	);
};

renderDateTimePickerField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired
};

export default renderDateTimePickerField;
