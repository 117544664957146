import React from 'react';
import LoginCard from '../../../shared/components/login/LoginCard';

const LogInPhoto = () => (
	<div className="account account--photo">
		<LoginCard />
	</div>
);

export default LogInPhoto;
