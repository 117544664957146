import React, { PureComponent } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import { compose } from 'redux';
import { getProcessing, getError } from '../../../../redux/selectors';
import { unloadAuthPage, resetPassword } from '../../../../redux/actions/authActions';
import { minLength, required, passwordsMustMatch } from '../../../../shared/components/login/formValidator';
import requireAnonymous from '../../../../hoc/requireAnonymous';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
class ResetPasswordForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		errorMessage: PropTypes.string,
		errorMsg: PropTypes.string,
		form: PropTypes.string.isRequired
	};

	static defaultProps = {
		errorMessage: '',
		errorMsg: ''
	};

	constructor() {
		super();
		this.state = {
			showPassword: false
		};

		this.showPassword = this.showPassword.bind(this);
	}

	showPassword(e) {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	}

	onSubmit = (formValues) => {
		console.log('-- formValues : ', this.props);
		delete formValues.repassword;
		return this.props.resetPassword(formValues, this.props.match.params.token).then(() => {
			if (this.props.errorMessage) {
			} else {
				this.props.history.push('/login');
			}
		});
	};

	render() {
		const { handleSubmit, errorMessage, form, valid, pristine, submitting, error } = this.props;
		const { showPassword } = this.state;

		return (
			<Form className="form forgot-form" onSubmit={handleSubmit(this.onSubmit)}>
				<Alert color="danger" isOpen={!!error || !!errorMessage}>
					{error}
					{errorMessage}
				</Alert>
				<div className="form__form-group">
					<span className="form__form-group-label">Password</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field name="password" component="input" type={showPassword ? 'text' : 'password'} placeholder="Password" />
						<button type="button" className={`form__form-group-button${showPassword ? ' active' : ''}`} onClick={(e) => this.showPassword(e)}>
							<EyeIcon />
						</button>
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Re-Password</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field name="repassword" component="input" type={showPassword ? 'text' : 'password'} placeholder="Retype Password" />
						<button type="button" className={`form__form-group-button${showPassword ? ' active' : ''}`} onClick={(e) => this.showPassword(e)}>
							<EyeIcon />
						</button>
					</div>
				</div>
				<div className="form__form-group">
					<Button className="account__btn" disabled={pristine || submitting || !valid} submit="true" type="submit" color="primary">
						Submit
					</Button>
				</div>
			</Form>
		);
	}

	componentWillUnmount() {
		this.props.unloadAuthPage();
	}
}

const maptStateToProps = (state) => {
	return {
		isProcessing: getProcessing(state),
		errorMessage: getError(state)
	};
};

const validate = (values) => {
	const errors = {};
	errors.password = required(values.password) || minLength(8)(values.password);
	errors.repassword = required(values.repassword) || minLength(8)(values.repassword) || passwordsMustMatch(values.repassword, values);
	return errors;
};

export default compose(
	requireAnonymous(),
	connect(maptStateToProps, {
		unloadAuthPage,
		resetPassword
	}),
	reduxForm({ form: 'fromResetPassword', validate })
)(ResetPasswordForm);
