import { replace } from 'connected-react-router';
import * as actionTypes from './types';
import isEqual from 'lodash/isEqual';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';

export const setWebSocketInstance = (payload) => ({
	type: actionTypes.SET_WEBSOCKET_INSTANCE,
	payload
});

export const setIsSocketClosed = (payload) => ({
	type: actionTypes.SET_IS_SOCKET_CLOSED,
	payload
});

const socketConnect = (dispatch, getState) => {
	try {
		const endpoint = process.env.REACT_APP_API_URL.split('://')[1].replace('/', '');
		let ws; // = new WebSocket(`wss://${endpoint}`);
		if (/127.0.0.1/.test(endpoint)) ws = new WebSocket(`ws://${endpoint}`);
		else ws = new WebSocket(`wss://${endpoint}`);
		console.log('endpoint:', endpoint);
		console.log('endpoint2:', process.env.REACT_APP_API_URL);

		ws.onclose = function(closeEvent) {
			console.log('Socket connection was closed. Event object emitted: ', closeEvent);
			// Alert app that socket connection is closed.
			dispatch(setIsSocketClosed(true));
		};

		ws.onmessage = async function(event) {
			if (event.data === 'STATUS') {
				dispatch({ type: actionTypes.DEVICE_STATUS_CHANGE });
			}
		};

		// When readystate is 1, we send our kiosk ID to the server and store the websocket instance
		// into app state.
		ws.onopen = function(event) {
			console.log('Websocket connection established');
			// Store the connection instance into the redux store
			dispatch(setWebSocketInstance(ws));
			dispatch(setIsSocketClosed(false));
		};
	} catch (err) {
		console.log('Error establishing socket connection: ', err);
	}
};

export function auth({ name, avatar }) {
	return {
		type: AUTHENTICATE,
		user: { name, avatar }
	};
}

export function authError(error) {
	return {
		type: AUTHENTICATE_ERROR_AUTH,
		error
	};
}

export const signUp = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.SIGN_UP });
	return mernApi.post('/auth/signup', formValues).then(
		() => {
			dispatch({ type: actionTypes.SIGN_UP_SUCCESS });
		},
		(err) => {
			// eslint-disable-next-line no-use-before-define
			dispatch(signUpFail(err.response.data.error));
		}
	);
};

const setAuthInfo = (authInfo, mernApi) => {
	mernApi.setAuthToken(authInfo.token);
	localStorage.setItem('authInfo', JSON.stringify(authInfo));
};

const clearAuthInfo = (mernApi) => {
	mernApi.setAuthToken('');
	localStorage.removeItem('authInfo');
	localStorage.removeItem('switchClient');
	localStorage.removeItem('recentClients');
};

export const setAttemptedPath = (path) => ({
	type: actionTypes.SET_ATTEMPTED_PATH,
	payload: path
});

const redirectAfterSignIn = (dispatch, getState) => {
	if (getState().auth.attemptedPath) {
		// dispatch(replace(getState().auth.attemptedPath));
		// dispatch(setAttemptedPath(null));
	} else {
		dispatch(replace(getState().auth.defaultPath));
	}
};

const signInHelper = (endpoint, payload, actionSuccess, actionFail, dispatch, getState, mernApi) =>
	mernApi.post(endpoint, payload).then(
		(response) => {
			localStorage.removeItem('switchClient');
			localStorage.removeItem('recentClients');
			if (response.data.user.role == 'customer') {
				return dispatch(actionFail('You have no permission to access this page'));
			}

			setAuthInfo(response.data, mernApi);
			socketConnect(dispatch, getState);
			dispatch(actionSuccess(response.data));
			redirectAfterSignIn(dispatch, getState);
		},
		(err) => {
			console.log('error', err);
			if (err.response) {
				dispatch(actionFail(err.response.data.error));
			} else {
				dispatch(actionFail('Server is not alive'));
			}
		}
	);

const signUpFail = (payload) => ({
	type: actionTypes.SIGN_UP_FAIL,
	payload
});

const signInSuccess = (payload) => ({
	type: actionTypes.SIGN_IN_SUCCESS,
	payload
});

const setSwitchClientSucess = (payload) => ({
	type: actionTypes.SET_SWITCH_CLIENT,
	payload
});

export const setSwitchClient = (client) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.SWITCH_CLIENT_PROCESSING });

	if (client === undefined || client._id === undefined) {
		return;
	}
	let recentClients = localStorage.getItem('recentClients');
	if (recentClients) {
		try {
			recentClients = JSON.parse(recentClients);
			if (recentClients.length > 2) {
				recentClients.shift();
			}
			if (!recentClients.includes(client._id)) recentClients.push(client._id);
			localStorage.setItem('recentClients', JSON.stringify(recentClients));
		} catch (e) {}
	} else {
		recentClients = [client._id];
		localStorage.setItem('recentClients', JSON.stringify(recentClients));
	}
	localStorage.setItem('switchClient', client._id.toString());
	if (!isEqual(client, getState().switchClient.switch_client)) return dispatch(setSwitchClientSucess(client));
};

const signInFail = (payload) => ({
	type: actionTypes.SIGN_IN_FAIL,
	payload
});

export const signIn = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.SIGN_IN });
	return signInHelper('/auth/signin', formValues, signInSuccess, signInFail, dispatch, getState, mernApi);
};

const tryLocalSignInSuccess = (payload) => (dispatch, getState, { mernApi }) => {
	setAuthInfo(payload, mernApi);
	dispatch({
		type: actionTypes.TRY_LOCAL_SIGN_IN_SUCCESS,
		payload
	});
	if (payload.user.role == 'customer') {
		dispatch({
			type: actionTypes.SET_SWITCH_CLIENT,
			payload: null
		});
	}
	socketConnect(dispatch, getState);
};

const tryLocalSignInFail = () => (dispatch, getState, { mernApi }) => {
	clearAuthInfo(mernApi);
	dispatch({ type: actionTypes.TRY_LOCAL_SIGN_IN_FAIL });
	dispatch(replace('/login'));
};

// eslint-disable-next-line consistent-return
export const tryLocalSignIn = () => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.TRY_LOCAL_SIGN_IN });
	try {
		const authInfo = JSON.parse(localStorage.getItem('authInfo'));
		const now = Math.floor(Date.now() / 1000);
		if (!authInfo || (authInfo && authInfo.expiresAt <= now)) {
			dispatch(tryLocalSignInFail());
			return Promise.resolve();
		}

		if (authInfo.user.role == 'customer') {
			return dispatch(tryLocalSignInFail());
		}

		// if token age > 30 days, then refresh token
		if (authInfo.expiresAt <= now + 30 * 24 * 60 * 60) {
			mernApi.setAuthToken(authInfo.token);
			return mernApi.post('auth/refresh-token').then(
				(response) => {
					if (response.data.user.role == 'customer') {
						return dispatch(tryLocalSignInFail());
					}

					authInfo.token = response.data.token;
					authInfo.expiresAt = response.data.expiresAt;
					dispatch(tryLocalSignInSuccess(authInfo));
					redirectAfterSignIn(dispatch, getState);
					setAuthInfo(authInfo, mernApi);
				},
				(err) => {
					console.log(err);
					dispatch(tryLocalSignInFail());
				}
			);
		}
		dispatch(tryLocalSignInSuccess(authInfo));
		redirectAfterSignIn(dispatch, getState);
		setAuthInfo(authInfo, mernApi);
		return Promise.resolve();
	} catch (err) {
		dispatch(tryLocalSignInFail(err));
	}
};

const verifyEmailFail = (payload) => ({
	type: actionTypes.VERIFY_EMAIL_FAIL,
	payload
});

const requestVerificationEmailFail = (payload) => ({
	type: actionTypes.REQUEST_VERIFICATION_EMAIL_FAIL,
	payload
});

export const signOut = () => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.SIGN_OUT });
	clearAuthInfo(mernApi);
	dispatch({ type: actionTypes.SIGN_OUT_SUCCESS });
};

export const verifyEmail = (token) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.VERIFY_EMAIL });
	return mernApi.post(`/auth/verify-email/${token}`).then(
		() => {
			dispatch({ type: actionTypes.VERIFY_EMAIL_SUCCESS });
		},
		(err) => {
			dispatch(verifyEmailFail(err.response.data.error));
		}
	);
};

export const requestVerificationEmail = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.REQUEST_VERIFICATION_EMAIL });
	return mernApi.post('/auth/send-token', formValues).then(
		() => {
			dispatch({ type: actionTypes.REQUEST_VERIFICATION_EMAIL_SUCCESS });
		},
		(err) => {
			dispatch(requestVerificationEmailFail(err.response.data.error));
		}
	);
};

const requestPasswordResetFail = (payload) => ({
	type: actionTypes.REQUEST_PASSWORD_RESET_FAIL,
	payload
});

export const requestPasswordReset = (formValues) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.REQUEST_PASSWORD_RESET });
	return mernApi.post('/auth/send-token', formValues).then(
		(res) => {
			dispatch({ type: actionTypes.REQUEST_PASSWORD_RESET_SUCCESS });
			return res;
		},
		(err) => {
			console.log(err.error);
			if (err.response) dispatch(requestPasswordResetFail(err.response.data.error));
			else dispatch(requestPasswordResetFail(err.error || err.message));
		}
	);
};

const resetPasswordFail = (payload) => ({
	type: actionTypes.RESET_PASSWORD_FAIL,
	payload
});

export const resetPassword = (formValues, token) => (dispatch, getState, { mernApi }) => {
	dispatch({ type: actionTypes.RESET_PASSWORD });
	return mernApi.post(`/auth/reset-password/${token}`, formValues).then(
		() => {
			dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
		},
		(err) => {
			if (err && err.response && err.response.data) dispatch(resetPasswordFail(err.response.data.error));
			else dispatch(resetPasswordFail(err.message || err));
		}
	);
};

export const unloadAuthPage = () => ({
	type: actionTypes.UNLOAD_AUTH_PAGE
});
