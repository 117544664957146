import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	category: {},
	categories: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const categoryReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				category: {},
				categories: []
			};
		case actionTypes.LOAD_CATEGORIES:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_CATEGORY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				category: {}
			};
		case actionTypes.ADD_CATEGORY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				category: {}
			};
		case actionTypes.DELETE_CATEGORY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				category: {}
			};
		case actionTypes.UPDATE_CATEGORY:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_CATEGORIES_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				categories: action.payload.categories
			};
		case actionTypes.LOAD_CATEGORY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				category: { ...action.payload.category }
			};
		case actionTypes.ADD_CATEGORY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				category: { ...action.payload.category },
				categories: action.payload.categories
			};
		case actionTypes.DELETE_CATEGORY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				categories: action.payload.categories
			};
		case actionTypes.UPDATE_CATEGORY_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				category: { ...action.payload.category },
				categories: action.payload.categories
			};
		case actionTypes.LOAD_CATEGORIES_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.LOAD_CATEGORY_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_CATEGORY_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_CATEGORY_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default categoryReducer;
