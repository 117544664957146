import React, { PureComponent } from 'react';
import LoginCard from '../../../shared/components/login/LoginCard';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { tryLocalSignIn } from '../../../redux/actions/authActions';
import StarBackground from './components/StarBackground';

class LogIn extends PureComponent {
	componentWillMount() {
		console.log('Loing page will Mount');
		this.props.tryLocalSignIn();
		document.title = 'Envent Cloud';
	}
	render() {
		return (
			<div className="star-background account account--not-photo">
				<StarBackground />
				<LoginCard />
			</div>
		);
	}
}

export default compose(connect(null, { tryLocalSignIn })(LogIn));
