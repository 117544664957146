export const SIGN_UP = '[Auth] Sign Up';
export const SIGN_UP_SUCCESS = '[Auth] Sign Up Success';
export const SIGN_UP_FAIL = '[Auth] Sign Up Fail';

export const SIGN_IN = '[Auth] Sign In';
export const SIGN_IN_SUCCESS = '[Auth] Sign In Success';
export const SIGN_IN_FAIL = '[Auth] Sign In Fail';

export const FACEBOOK_SIGN_IN = '[Auth] Facebook Sign In';
export const FACEBOOK_SIGN_IN_SUCCESS = '[Auth] Facebook Sign In Success';
export const FACEBOOK_SIGN_IN_FAIL = '[Auth] Facebook Sign In Fail';

export const GOOGLE_SIGN_IN = '[Auth] Google Sign In';
export const GOOGLE_SIGN_IN_SUCCESS = '[Auth] Google Sign In Success';
export const GOOGLE_SIGN_IN_FAIL = '[Auth] Google Sign In Fail';

export const TRY_LOCAL_SIGN_IN = '[Auth] Try Local Sign In';
export const TRY_LOCAL_SIGN_IN_SUCCESS = '[Auth] Try Local Sign In Success';
export const TRY_LOCAL_SIGN_IN_FAIL = '[Auth] Try Local Sign In Fail';

export const SIGN_OUT = '[Auth] Sign Out';
export const SIGN_OUT_SUCCESS = '[Auth] Sign Out Success';

export const SET_SWITCH_CLIENT = '[Auth] Set the switched client';
export const SWITCH_CLIENT_PROCESSING = '[Auth] Setting the switch client';
export const SET_DEFAULT_URL = '[Auth] Set Default Path';
export const SET_ATTEMPTED_PATH = '[Auth] Set Attempted Path';

export const VERIFY_EMAIL = '[Auth] Verify Email';
export const VERIFY_EMAIL_SUCCESS = '[Auth] Verify Email Success';
export const VERIFY_EMAIL_FAIL = '[Auth] Verify Email Fail';

export const REQUEST_VERIFICATION_EMAIL = '[Auth] Request Verification Email';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS = '[Auth] Request Verification Email Success';
export const REQUEST_VERIFICATION_EMAIL_FAIL = '[Auth] Request Verification Email Fail';

export const REQUEST_PASSWORD_RESET = '[Auth] Request Password Reset';
export const REQUEST_PASSWORD_RESET_SUCCESS = '[Auth] Request Password Reset Success';
export const REQUEST_PASSWORD_RESET_FAIL = '[Auth] Request Password Reset Fail';

export const RESET_PASSWORD = '[Auth] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success';
export const RESET_PASSWORD_FAIL = '[Auth] Reset Password Fail';

export const SET_WEBSOCKET_INSTANCE = 'SET_WEBSOCKET_INSTANCE';
export const SET_IS_SOCKET_CLOSED = 'SET_IS_SOCKET_CLOSED';

export const UNLOAD_AUTH_PAGE = '[Auth] Unload Auth Page';

export const LOAD_MAPS = '[Map] Load Maps';
export const LOAD_MAPS_SUCCESS = '[Map] Load Maps Success';
export const LOAD_MAPS_FAIL = '[Map] Load Maps Fail';

export const LOAD_MAP = '[Map] Load Map';
export const LOAD_MAP_SUCCESS = '[Map] Load Map Success';
export const LOAD_MAP_FAIL = '[Map] Load Map Fail';

export const DELETE_MAP = '[Map] Delete Map';
export const DELETE_MAP_SUCCESS = '[Map] Delete Map Success';
export const DELETE_MAP_FAIL = '[Map] Delete Map Fail';

export const GET_MAP_DATA = '[Map] GET_MAP_DATA';
export const GET_MAP_DATA_SUCCESS = '[Map] GET_MAP_DATA Success';
export const GET_MAP_DATA_FAIL = '[Map] GET_MAP_DATA Fail';

export const SET_CURRENT_MAP = '[Map] SET CURRENT MAP';

export const ADD_MAP = '[Map] Add Map';
export const ADD_MAP_SUCCESS = '[Map] Add Map Success';
export const ADD_MAP_FAIL = '[Map] Add Map Fail';

export const UPDATE_MAP = '[Map] Update Map';
export const UPDATE_MAP_SUCCESS = '[Map] Update Map Success';
export const UPDATE_ACTIVE_MAP_SUCCESS = '[Map] Update map active success';
export const UPDATE_MAP_FAIL = '[Map] Update Map Fail';

export const UPLOAD_FILE = '[Map] Upload File';
export const UPLOAD_FILE_SUCCESS = '[Map] Upload File Success';
export const UPLOAD_FILE_FAIL = '[Map] Upload File Fail';

export const LOAD_CLIENTS = '[Client] Load Clients';
export const LOAD_CLIENTS_SUCCESS = '[Client] Load Clients Success';
export const LOAD_CLIENTS_FAIL = '[Client] Load Clients Fail';

export const LOAD_CLIENT = '[Client] Load Client';
export const LOAD_CLIENT_SUCCESS = '[Client] Load Client Success';
export const LOAD_CLIENT_FAIL = '[Client] Load Client Fail';

export const LOAD_DASH_CLIENT = '[Client] Load  dash Client';
export const LOAD_DASH_CLIENT_SUCCESS = '[Client] Load dash Client Success';
export const LOAD_DASH_CLIENT_FAIL = '[Client] Load dash Client Fail';

export const DELETE_CLIENT = '[Client] DELETE_CLIENT';
export const DELETE_CLIENTS_SUCCESS = '[Client] DELETE_CLIENTS_SUCCESS';
export const DELETE_CLIENTS_FAIL = '[Client] DELETE_CLIENTS_FAIL';

export const ADD_CLIENT = '[Client] Add Client';
export const ADD_CLIENT_SUCCESS = '[Client] Add Client Success';
export const ADD_CLIENT_FAIL = '[Client] Add Client Fail';

export const UPDATE_CLIENT = '[Client] Update Client';
export const UPDATE_CLIENT_SUCCESS = '[Client] Update Client Success';
export const UPDATE_CLIENT_FAIL = '[Client] Update Client Fail';

export const LOAD_USERS = '[User] Load Users';
export const LOAD_USERS_SUCCESS = '[User] Load Users Success';
export const LOAD_USERS_FAIL = '[User] Load Users Fail';

export const LOAD_USER = '[User] Load User';
export const LOAD_USER_SUCCESS = '[User] Load User Success';
export const LOAD_USER_FAIL = '[User] Load User Fail';

export const ADD_USER = '[User] Add Client';
export const ADD_USER_SUCCESS = '[User] Add User Success';
export const ADD_USER_FAIL = '[User] Add User Fail';

export const UPDATE_USER = '[User] Update User';
export const UPDATE_USER_SUCCESS = '[User] Update User Success';
export const UPDATE_USER_FAIL = '[User] Update User Fail';

export const LOAD_ADVERTISEMENTS = '[Advertisement] Load Advertisements';
export const LOAD_ADVERTISEMENTS_SUCCESS = '[Advertisement] Load Advertisements Success';
export const LOAD_ADVERTISEMENTS_FAIL = '[Advertisement] Load Advertisements Fail';

export const LOAD_ADVERTISEMENT = '[Advertisement] Load Advertisement';
export const LOAD_ADVERTISEMENT_SUCCESS = '[Advertisement] Load Advertisement Success';
export const LOAD_ADVERTISEMENT_FAIL = '[Advertisement] Load Advertisement Fail';

export const ADD_ADVERTISEMENT = '[Advertisement] Add Advertisement';
export const ADD_ADVERTISEMENT_SUCCESS = '[Advertisement] Add Advertisement Success';
export const ADD_ADVERTISEMENT_FAIL = '[Advertisement] Add Advertisement Fail';

export const UPDATE_ADVERTISEMENT = '[Advertisement] Update Advertisement';
export const UPDATE_ADVERTISEMENT_SUCCESS = '[Advertisement] Update Advertisement Success';
export const UPDATE_ADVERTISEMENT_FAIL = '[Advertisement] Update Advertisement Fail';

export const UPDATE_ADVERTISEMENT_ORDER_SUCCESS = '[Advertisement] Update Advertisement Order Success';

export const DELETE_ADVERTISEMENTS = '[Advertisement] Delete Advertisements';
export const DELETE_ADVERTISEMENTS_SUCCESS = '[Advertisement] Delete Advertisements Success';
export const DELETE_ADVERTISEMENTS_FAIL = '[Advertisement] Delete Advertisements Fail';

/* EVENTS */
export const LOAD_EVENTS = '[Events] Load Events';
export const LOAD_EVENTS_SUCCESS = '[Events] Load Events Success';
export const LOAD_EVENTS_FAIL = '[Events] Load Events Fail';

export const LOAD_EVENT = '[Events] Load Event';
export const LOAD_EVENT_SUCCESS = '[Events] Load Event Success';
export const LOAD_EVENT_FAIL = '[Events] Load Event Fail';

export const ADD_EVENT = '[Events] Add Event';
export const ADD_EVENT_SUCCESS = '[Events] Add Event Success';
export const ADD_EVENT_FAIL = '[Events] Add Event Fail';

export const UPDATE_EVENT = '[Events] Update Event';
export const UPDATE_EVENT_SUCCESS = '[Events] Update Event Success';
export const UPDATE_EVENT_FAIL = '[Events] Update Event Fail';

export const DELETE_EVENTS = '[Events] Delete Events';
export const DELETE_EVENTS_SUCCESS = '[Events] Delete Events Success';
export const DELETE_EVENTS_FAIL = '[Events] Delete Events Fail';

/* --- --- ---*/

export const LOAD_DEVICES = '[Device] Load Devices';
export const LOAD_DEVICES_SUCCESS = '[Device] Load Devices Success';
export const LOAD_DEVICES_FAIL = '[Device] Load Devices Fail';

export const LOAD_DEVICE = '[Device] Load Device';
export const LOAD_DEVICE_SUCCESS = '[Device] Load Device Success';
export const LOAD_DEVICE_FAIL = '[Device] Load Device Fail';

export const ADD_DEVICE = '[Device] Add Device';
export const ADD_DEVICE_SUCCESS = '[Device] Add Device Success';
export const ADD_DEVICE_FAIL = '[Device] Add Device Fail';

export const UPDATE_DEVICE = '[Device] Update Device';
export const UPDATE_DEVICE_SUCCESS = '[Device] Update Device Success';
export const UPDATE_DEVICE_FAIL = '[Device] Update Device Fail';

export const ADD_CATEGORY = '[Category] Add Static Category';
export const ADD_CATEGORY_SUCCESS = '[Category] Add Static Category Success';
export const ADD_CATEGORY_FAIL = '[Category] Add Static Category Fail';

export const LOAD_CATEGORY = '[Category] Load Static Category';
export const LOAD_CATEGORY_SUCCESS = '[Category] Load Static Category Success';
export const LOAD_CATEGORY_FAIL = '[Category] Load Static Category Fail';

export const LOAD_CATEGORIES = '[Category] Load Static Categories';
export const LOAD_CATEGORIES_SUCCESS = '[Category] Load Static Categories Success';
export const LOAD_CATEGORIES_FAIL = '[Category] Load Static Categories Fail';

export const UPDATE_CATEGORY = '[Category] Update Static Category';
export const UPDATE_CATEGORY_SUCCESS = '[Category] Update Static Category Success';
export const UPDATE_CATEGORY_FAIL = '[Category] Update Static Category Fail';

export const DELETE_CATEGORY = '[Category] DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = '[Category] DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = '[Category] DELETE_CATEGORY_FAIL';

export const ADD_STATIC_CATEGORY = '[Category] Add Static Category';
export const ADD_STATIC_CATEGORY_SUCCESS = '[Category] Add Static Category Success';
export const ADD_STATIC_CATEGORY_FAIL = '[Category] Add Static Category Fail';

export const LOAD_STATIC_CATEGORY = '[Category] Load Static Category';
export const LOAD_STATIC_CATEGORY_SUCCESS = '[Category] Load Static Category Success';
export const LOAD_STATIC_CATEGORY_FAIL = '[Category] Load Static Category Fail';

export const LOAD_STATIC_CATEGORIES = '[Category] Load Static Categories';
export const LOAD_STATIC_CATEGORIES_SUCCESS = '[Category] Load Static Categories Success';
export const LOAD_STATIC_CATEGORIES_FAIL = '[Category] Load Static Categories Fail';

export const UPDATE_STATIC_CATEGORY = '[Category] Update Static Category';
export const UPDATE_STATIC_CATEGORY_SUCCESS = '[Category] Update Static Category Success';
export const UPDATE_STATIC_CATEGORY_FAIL = '[Category] Update Static Category Fail';

export const ADD_META_TAG = '[Meta Tag] Add Meta Tag';
export const ADD_META_TAG_SUCCESS = '[Meta Tag] Add Meta Tag Success';
export const ADD_META_TAG_FAIL = '[Meta Tag] Add Meta Tag Fail';

export const LOAD_META_TAGS = '[Meta Tag] Load Meta Tags';
export const LOAD_META_TAGS_SUCCESS = '[Meta Tag] Load Meta Tags Success';
export const LOAD_META_TAGS_FAIL = '[Meta Tag] Load Meta Tags Fail';

export const ADD_TENANT = '[Tenant] Add Tenant';
export const ADD_TENANT_SUCCESS = '[Tenant] Add Tenant Success';
export const ADD_TENANT_FAIL = '[Tenant] Add Tenant Fail';

export const LOAD_TENANT = '[Tenant] Load Tenant';
export const LOAD_TENANT_SUCCESS = '[Tenant] Load Tenant Success';
export const LOAD_TENANT_FAIL = '[Tenant] Load Tenant Fail';

export const LOAD_TENANTS = '[Tenant] Load Tenants';
export const LOAD_TENANTS_SUCCESS = '[Tenant] Load Tenants Success';
export const LOAD_TENANTS_FAIL = '[Tenant] Load Tenants Fail';

export const UPDATE_TENANT = '[Tenant] Update Tenant';
export const UPDATE_TENANT_SUCCESS = '[Tenant] Update Tenant Success';
export const UPDATE_TENANT_FAIL = '[Tenant] Update Tenant Fail';

export const ADD_STATIC_TENANT = '[Tenant] Add Static Tenant';
export const ADD_STATIC_TENANT_SUCCESS = '[Tenant] Add Static Tenant Success';
export const ADD_STATIC_TENANT_FAIL = '[Tenant] Add Static Tenant Fail';

export const LOAD_STATIC_TENANT = '[Tenant] Load Static Tenant';
export const LOAD_STATIC_TENANT_SUCCESS = '[Tenant] Load Static Tenant Success';
export const LOAD_STATIC_TENANT_FAIL = '[Tenant] Load Static Tenant Fail';

export const LOAD_STATIC_TENANTS = '[Tenant] Load Static Tenants';
export const LOAD_STATIC_TENANTS_SUCCESS = '[Tenant] Load Static Tenants Success';
export const LOAD_STATIC_TENANTS_FAIL = '[Tenant] Load Static Tenants Fail';

export const UPDATE_STATIC_TENANT = '[Tenant] Update Static Tenant';
export const UPDATE_STATIC_TENANT_SUCCESS = '[Tenant] Update Static Tenant Success';
export const UPDATE_STATIC_TENANT_FAIL = '[Tenant] Update Static Tenant Fail';

export const ADD_SURVEY = '[Survey] Add Survey';
export const ADD_SURVEY_SUCCESS = '[Survey] Add Survey Success';
export const ADD_SURVEY_FAIL = '[Survey] Add Survey Fail';

export const LOAD_SURVEY = '[Survey] Load Survey';
export const LOAD_SURVEY_SUCCESS = '[Survey] Load Survey Success';
export const LOAD_SURVEY_FAIL = '[Survey] Load Survey Fail';

export const LOAD_SURVEYS = '[Survey] Load Surveys';
export const LOAD_SURVEYS_SUCCESS = '[Survey] Load Surveys Success';
export const LOAD_SURVEYS_FAIL = '[Survey] Load Surveys Fail';

export const UPDATE_SURVEY = '[Survey] Update Event';
export const UPDATE_SURVEY_SUCCESS = '[Survey] Update Survey Success';
export const UPDATE_SURVEY_FAIL = '[Survey] Update Survey Fail';

export const DELETE_SURVEY = '[Survey] Delete Survey';
export const DELETE_SURVEY_SUCCESS = '[Survey] Delete Survey Success';
export const DELETE_SURVEY_FAIL = '[Survey] Delete Survey Fail';

export const LOAD_DEVICE_STATUS = '[Device Status] Load Device Status';
export const LOAD_DEVICE_STATUS_SUCCESS = '[Device Status] Load Device Status Success';
export const LOAD_DEVICE_STATUS_FAIL = '[Device Status] Load Device Status Fail';

export const DEVICE_STATUS_CHANGE = '[Device Status] DEVICE_STATUS_CHANGE';

export const PUBLISH_UPDATES = 'PUBLISH_UPDATES';
export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS';
export const PUBLISH_FAIL = 'PUBLISH_FAIL';
export const LOAD_USAGE_LOGS = '[Usage Logs] Load Usage Logs';

export const LOAD_USAGE_LOGS_SUCCESS = '[Usage Logs] Load Usage Logs Success';

export const LOAD_USAGE_LOGS_FAIL = '[Usage Logs] Load Usage Logs FAIL';

export const LOAD_DOWNER_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_DOWNER_REPORT = 'LOAD_REPORT_DOWNER';
export const LOAD_DOWNER_REPORT_FAIL = 'LOAD_REPORT_FAIL';

export const LOAD_DOWNER_STATISTICAL_REPORT_SUCCESS = 'LOAD_DOWNER_STATISTICAL_SUCCESS';
export const LOAD_DOWNER_STATISTICAL_REPORT = 'LOAD_DOWNER_STATISTICAL_DOWNER';
export const LOAD_DOWNER_STATISTICAL_REPORT_FAIL = 'LOAD_DOWNER_STATISTICAL_FAIL';

export const LOAD_USER_DOWNER_STATISTICAL_REPORT_SUCCESS = 'LOAD_USER_DOWNER_STATISTICAL_SUCCESS';
export const LOAD_USER_DOWNER_STATISTICAL_REPORT = 'LOAD_USER_DOWNER_STATISTICAL_DOWNER';
export const LOAD_USER_DOWNER_STATISTICAL_REPORT_FAIL = 'LOAD_USER_DOWNER_STATISTICAL_FAIL';

export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_REPORT = 'LOAD_REPORT';
export const LOAD_REPORT_FAIL = 'LOAD_REPORT_FAIL';

export const ADD_QUICK = '[QUICK] Add QUICK';
export const ADD_QUICK_SUCCESS = '[QUICK] Add QUICK Success';
export const ADD_QUICK_FAIL = '[QUICK] Add QUICK Fail';

export const LOAD_QUICK = '[QUICK] Load QUICK';
export const LOAD_QUICK_SUCCESS = '[QUICK] Load QUICK Success';
export const LOAD_QUICK_FAIL = '[QUICK] Load QUICK Fail';

export const LOAD_QUICKS = '[QUICK] Load QUICKS';
export const LOAD_QUICKS_SUCCESS = '[QUICK] Load QUICKS Success';
export const LOAD_QUICKS_FAIL = '[QUICK] Load QUICKS Fail';

export const UPDATE_QUICK = '[QUICK] Update QUICK';
export const UPDATE_QUICK_SUCCESS = '[QUICK] Update QUICK Success';
export const UPDATE_QUICK_FAIL = '[QUICK] Update QUICK Fail';

export const DELETE_QUICK = '[QUICK] DELETE QUICK';
export const DELETE_QUICK_SUCCESS = '[QUICK] DELETE QUICK Success';
export const DELETE_QUICK_FAIL = '[QUICK] DELETE QUICK Fail';

export const LOAD_INFORMATIONS = '[Information] LOAD_INFORMATIONS';
export const LOAD_INFORMATIONS_SUCCESS = '[Information] LOAD_INFORMATIONS_SUCCESS';
export const LOAD_INFORMATIONS_FAIL = '[Information] LOAD_INFORMATIONS_FAIL';

export const LOAD_INFORMATION = '[Information] LOAD_INFORMATION';
export const LOAD_INFORMATION_SUCCESS = '[Information] LOAD_INFORMATION_SUCCESS';
export const LOAD_INFORMATION_FAIL = '[Information] LOAD_INFORMATION_FAIL';

export const ADD_INFORMATION = '[Information] ADD_INFORMATION';
export const ADD_INFORMATION_SUCCESS = '[Information] ADD_INFORMATION_SUCCESS';
export const ADD_INFORMATION_FAIL = '[Information] ADD_INFORMATION_FAIL';

export const UPDATE_INFORMATION = '[Information] UPDATE_INFORMATION';
export const UPDATE_INFORMATION_SUCCESS = '[Information] UPDATE_INFORMATION_SUCCESS';
export const UPDATE_INFORMATION_FAIL = '[Information] UPDATE_INFORMATION_FAIL';

export const DELETE_INFORMATION = '[Information] DELETE_INFORMATION';
export const DELETE_INFORMATION_SUCCESS = '[Information] DELETE_INFORMATION_SUCCESS';
export const DELETE_INFORMATION_FAIL = '[Information] DELETE_INFORMATION_FAIL';
