import { createSelector } from 'reselect';

export const getPublishData = createSelector(
	(state) => state.publish,
	(publishData) => publishData.updateDetails
);

export const getPublishError = createSelector(
	(state) => state.publish,
	(publishData) => publishData.error
);
