import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	advertisement: {},
	advertisements: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const advertisementReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				advertisement: {},
				advertisements: []
			};
		case actionTypes.LOAD_ADVERTISEMENTS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_ADVERTISEMENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				advertisement: {}
			};
		case actionTypes.ADD_ADVERTISEMENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				advertisement: {}
			};
		case actionTypes.UPDATE_ADVERTISEMENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.DELETE_ADVERTISEMENTS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_ADVERTISEMENTS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				advertisements: action.payload.ads,
				defaultPath: '/dashboard'
			};
		case actionTypes.LOAD_ADVERTISEMENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				advertisement: { ...action.payload.advertisement }
			};
		case actionTypes.ADD_ADVERTISEMENT_SUCCESS:
		case actionTypes.UPDATE_ADVERTISEMENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				advertisement: { ...action.payload.advertisement }
			};
		case actionTypes.DELETE_ADVERTISEMENTS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				advertisements: action.payload.ads
			};
		case actionTypes.LOAD_ADVERTISEMENT_FAIL:
		case actionTypes.ADD_ADVERTISEMENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_ADVERTISEMENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.DELETE_ADVERTISEMENTS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default advertisementReducer;
