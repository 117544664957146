import { createSelector } from 'reselect';

export const getDeviceStatusState = (state) => state.deviceStatus;

export const getDeviceStatusProcessing = createSelector(getDeviceStatusState, (device_status) => device_status.processing);

export const getDeviceStatusProcessed = createSelector(getDeviceStatusState, (device_status) => device_status.processed);

export const getDeviceStatusError = createSelector(getDeviceStatusState, (device_status) => device_status.error);

export const getDeviceStatus = createSelector(getDeviceStatusState, (device_status) => device_status.device_status);

export const getDeviceStatusChange = createSelector(getDeviceStatusState, (device_status) => device_status.change);
