import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

const rows = [
	{
		id: 'deviceName',
		disablePadding: true,
		label: 'Device Name'
	},
	{
		id: 'deviceLocation',
		disablePadding: false,
		label: 'Location'
	},
	{
		id: 'status',
		disablePadding: false,
		label: 'Update Status'
	}
];

class PublishTableHead extends PureComponent {
	static propTypes = {
		onRequestSort: PropTypes.func.isRequired,
		order: PropTypes.string.isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
		rtl: RTLProps.isRequired
	};

	createSortHandler = (property) => (event) => {
		const { onRequestSort } = this.props;
		onRequestSort(event, property);
	};

	render() {
		const { order, orderBy, rowCount, rtl } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map(
						(row) => (
							<TableCell className="material-table__cell material-table__cell--sort material-table__cell-right" key={row.id} align={rtl.direction === 'rtl' ? 'right' : 'left'} padding={row.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === row.id ? order : false}>
								<TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)} className="material-table__sort-label" dir="ltr">
									{row.label}
								</TableSortLabel>
							</TableCell>
						),
						this
					)}
				</TableRow>
			</TableHead>
		);
	}
}

export default connect((state) => ({
	rtl: state.rtl
}))(PublishTableHead);
