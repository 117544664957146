import { createSelector } from 'reselect';

export const getStaticCategoryState = (state) => state.staticCategory;

export const getStaticCategoriesProcessing = createSelector(getStaticCategoryState, (category) => category.processing);

export const getStaticCategoriesProcessed = createSelector(getStaticCategoryState, (category) => category.processed);

export const getStaticCategoriesError = createSelector(getStaticCategoryState, (category) => category.error);

export const getStaticCategories = createSelector(getStaticCategoryState, (category) => category.categories);

export const getStaticCategory = createSelector(getStaticCategoryState, (category) => category.category);
