import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	client: {},
	clients: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const clientReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_CLIENTS:
			return {
				...state,
				processing: true,
				processed: false,
				error: null,
				clients: []
			};
		case actionTypes.LOAD_CLIENT:
			return {
				...state,
				processing: true,
				processed: false,
				error: null,
				client: {}
			};
		case actionTypes.ADD_CLIENT:
			return {
				...state,
				processing: true,
				processed: false,
				error: null
			};
		case actionTypes.ADD_CLIENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_CLIENT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_CLIENTS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				clients: action.payload.clients,
				defaultPath: '/client/list',
				error: null
			};
		case actionTypes.LOAD_CLIENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				client: { ...action.payload.client }
			};
		case actionTypes.LOAD_DASH_CLIENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				dash_client: { ...action.payload.dash_client }
			};
		case actionTypes.ADD_CLIENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				client: { ...action.payload.client }
			};
		case actionTypes.UPDATE_CLIENT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				client: { ...action.payload.client }
			};
		case actionTypes.LOAD_CLIENTS_FAIL:
		case actionTypes.LOAD_DASH_CLIENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.LOAD_CLIENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_CLIENT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.DELETE_CLIENT:
			return {
				...state,
				deleted: false
			};
		case actionTypes.DELETE_CLIENTS_SUCCESS: {
			return {
				...state,
				deleted: true
			};
		}

		default:
			return state;
	}
};

export default clientReducer;
