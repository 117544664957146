import * as actionTypes from '../actions/types';
const INITIAL_STATE = {
	account: {},
	accounts: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const usersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_USERS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_USER:
			return {
				...state,
				processed: false,
				processing: true,
				account: {},
				error: null
			};
		case actionTypes.ADD_USER:
			return {
				...state,
				processed: false,
				processing: true,
				account: {},
				error: null
			};
		case actionTypes.UPDATE_USER:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_USERS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				accounts: action.payload.users,
				defaultPath: '/user/list'
			};
		case actionTypes.LOAD_USER_SUCCESS:
			console.log('LOAD_USER_SUCCESS', action.payload);
			return {
				...state,
				processing: false,
				processed: true,
				account: action.payload.user
			};
		case actionTypes.ADD_USER_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				account: { ...action.payload.user }
			};
		case actionTypes.UPDATE_USER_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				account: { ...action.payload.user }
			};
		case actionTypes.LOAD_USERS_FAIL:
		case actionTypes.LOAD_USER_FAIL:
		case actionTypes.UPDATE_USER_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_USER_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default usersReducer;
