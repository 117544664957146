import { createSelector } from 'reselect';

export const getUsersState = (state) => state.account;

export const getUsersProcessing = createSelector(getUsersState, (account) => account.processing);

export const getUsersProcessed = createSelector(getUsersState, (account) => account.processed);

export const getUsersError = createSelector(getUsersState, (account) => account.error);

export const getUsers = createSelector(getUsersState, (account) => account.accounts);

export const getUser = createSelector(getUsersState, (account) => account.account);
