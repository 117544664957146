import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	topDestinations: [],
	topCategories: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const usageLogsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_USAGE_LOGS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				topDestinations: [],
				topCategories: []
			};
		case actionTypes.LOAD_USAGE_LOGS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				topDestinations: action.payload.topDests,
				topCategories: action.payload.topCategories,
				defaultPath: '/dashboard'
			};
		case actionTypes.LOAD_DEVICE_STATUS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default usageLogsReducer;
