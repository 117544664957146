import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	// This data will depend on which action was called
	reportData: {},
	processing: false,
	processed: false,
	error: null
};

const reportsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_REPORT:
			return {
				...state,
				error: null,
				reportData: {},
				processing: true,
				processed: false
			};
		case actionTypes.LOAD_REPORT_SUCCESS:
			return {
				...state,
				processed: true,
				processing: false,
				error: null,
				reportData: action.payload
			};
		case actionTypes.LOAD_REPORT_FAIL:
			return {
				...state,
				processed: true,
				processing: false,
				error: action.payload,
				reportData: {}
			};
		default:
			return state;
	}
};

export default reportsReducer;
