import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Loading from '../../../../shared/components/Loading';
import requireRole from '../../../../hoc/requireRole';
import Forms from './Forms';

const Documentation = lazy(() => import('./Documentation'));
const Account = lazy(() => import('./Account'));
const AdvertisementsList = lazy(() => import('../../../Advertising/List'));
const CreateAdvert = lazy(() => import('../../../Advertising/create'));
const EditAdvert = lazy(() => import('../../../Advertising/edit'));
const AdvertisementsListNew = lazy(() => import('../../../AdvertisingNew/List'));
const CreateAdvertNew = lazy(() => import('../../../AdvertisingNew/create'));
const EditAdvertNew = lazy(() => import('../../../AdvertisingNew/edit'));
const AllMaps = lazy(() => import('../../../Wayfinding/MapDashboard'));
const MapsViewer = lazy(() => import('../../../Wayfinding/MapDashboard/MapsViewer'));
const WayFinder = lazy(() => import('./WayFinder'));
const ClientDashboard = lazy(() => import('../../../ManageClients/ClientDashboard'));
const ManageClients = lazy(() => import('./ManageClients'));
const ManageReport = lazy(() => import('./ManageReport'));
const ManageReportCustom = lazy(() => import('./ManageReportCustom'));
const ManageUsers = lazy(() => import('./ManageUsers'));
const ManageDevices = lazy(() => import('./ManageDevices'));
const ManageTenants = lazy(() => import('./ManageTenants'));
const NewMap = lazy(() => import('../../../Wayfinding/NewMap'));
const EditMapInfo = lazy(() => import('../../../Wayfinding/EditMapInfo'));
const CategoryManage = lazy(() => import('../../../Wayfinding/Categories'));
const Dashboard = lazy(() => import('../../../Dashboard'));
const CreateEvent = lazy(() => import('../../../Events/Create'));
const EditEvent = lazy(() => import('../../../Events/Edit'));
const EventsList = lazy(() => import('../../../Events/List'));
const CreateSurvey = lazy(() => import('../../../Survey/Create'));
const EditSurvey = lazy(() => import('../../../Survey/Edit'));
const SurveysList = lazy(() => import('../../../Survey/List'));
const DirectoryViewTenants = lazy(() => import('../../../Directory/Tenants/viewTenants'));
const DirectoryCreateTenant = lazy(() => import('../../../Directory/Tenants/newTenant'));
const DirectoryEditTenant = lazy(() => import('../../../Directory/Tenants/editTenant'));
const DirectoryCategories = lazy(() => import('../../../Directory/Categories'));
const QuickDestinationManage = lazy(() => import('../../../Wayfinding/QuickDestination'));
const InformationEdit = lazy(() => import('../../../Information/Edit'));
const InformationTable = lazy(() => import('../../../Information/List/components/InformationTable'));
const InformationList = lazy(() => import('../../../Information/List'));
const InformationCreate = lazy(() => import('../../../Information/Create'));
const DownerReport = lazy(() => import('../../../DownerReport/DownerReport'));
// import StatisticalReport from '../../../DownerReport/StatisticalReport';
const StatisticalReport = lazy(() => import('../../../DownerReport/StatisticalReport'));

export default () => {
	return (
		<div>
			<Layout />
			<div className="container__wrap">
				<Suspense
					fallback={
						// <div
						// 	style={{
						// 		position: 'absolute',
						// 		left: 0,
						// 		top: 0,
						// 		display: 'flex',
						// 		width: '100%',
						// 		height: '100%',
						// 		justifyContent: 'center',
						// 		alignItems: 'center'
						// 	}}
						// >
						// 	<PropagateLoader
						// 		color={'#F7923A'}
						// 		loading={true}
						// 		cssOverride={{
						// 			display: 'block',
						// 			margin: '0 auto',
						// 			borderColor: 'red'
						// 		}}
						// 		size={15}
						// 	/>
						// </div>
						<Loading loading />
					}
				>
					{/* CMS */}
					<Route exact path="/" component={Dashboard} />
					<Route exact path="/dashboard" component={Dashboard} />
					<Route path="/account" component={Account} />
					<Route path="/forms" component={Forms} />
					<Route path="/documentation" component={Documentation} />

					{/* Wayfinder */}
					<Route path="/wayfinder/maps" component={AllMaps} />
					<Route path="/wayfinder/mapsviewer" component={MapsViewer} />
					<Route path="/wayfinder/new" component={NewMap} />
					<Route path="/wayfinder/edit/:id" component={EditMapInfo} />
					<Route path="/wayfinder/tenant" component={ManageTenants} />
					<Route path="/wayfinder/category" component={CategoryManage} />
					<Route path="/wayfinder/quick" component={QuickDestinationManage} />
					<Route path="/wayfinder" component={WayFinder} />

					{/* Envent / Client Admin */}
					<Route path="/client" component={ManageClients} />
					<Route path="/client_dash/:id" component={ClientDashboard} />
					<Route path="/user" component={ManageUsers} />
					<Route path="/device" component={ManageDevices} />

					{/* Reports */}
					<Route path="/report" component={ManageReport} />
					<Route path="/report-custom" component={ManageReportCustom} />

					{/* Static Tenant Directory */}
					<Route path="/static-directory/tenant/view" component={DirectoryViewTenants} />
					<Route path="/static-directory/tenants/:id" component={DirectoryEditTenant} />
					<Route path="/static-directory/tenant/create" component={DirectoryCreateTenant} />
					<Route path="/static-directory/categories" component={DirectoryCategories} />

					{/* Advertising */}
					<Route path="/advertising/view" component={AdvertisementsList} />
					<Route path="/advertising/create" component={CreateAdvert} />
					<Route path="/advertising/pending" component={AdvertisementsList} />
					<Route path="/adver/:id" component={EditAdvert} />

					<Route path="/advertising-new/view" component={AdvertisementsListNew} />
					<Route path="/advertising-new/create" component={CreateAdvertNew} />
					<Route path="/advertising-new/pending" component={AdvertisementsListNew} />
					<Route path="/adver-new/:id" component={EditAdvertNew} />

					{/* Events */}
					<Route path="/events/create" component={CreateEvent} />
					<Route path="/events/view" component={EventsList} />
					<Route path="/evt/:id" component={EditEvent} />

					{/* Information */}
					<Route path="/inf/:id" component={InformationEdit} />
					<Route path="/info/create" component={InformationCreate} />
					<Route path="/info/view" component={InformationList} />

					{/* Survey */}
					<Route path="/surveys/create" component={CreateSurvey} />
					<Route path="/surveys/view" component={SurveysList} />
					<Route path="/surv/:id" component={EditSurvey} />

					{/* Downer Report */}
					<>
						<Route path="/downer/report" component={DownerReport} />
						<Route path="/downer/statistical" component={StatisticalReport} />
					</>
				</Suspense>
			</div>
		</div>
	);
};
