import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { UserProps, AuthOProps } from '../../../shared/prop-types/ReducerProps';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import { compose } from 'redux';
import requireRole from '../../../hoc/requireRole';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signOut } from '../../../redux/actions/authActions';
import { getCurrentUser, getSignedInWith } from '../../../redux/selectors';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
	static propTypes = {
		user: UserProps.isRequired
		// auth0: AuthOProps.isRequired
	};

	constructor() {
		super();
		this.state = {
			collapse: false
		};
	}

	toggle = () => {
		this.setState((prevState) => ({ collapse: !prevState.collapse }));
	};

	logout = () => {
		localStorage.removeItem('easydev');
		this.props.signOut();
	};

	render() {
		const { user, auth0 } = this.props;
		const { collapse } = this.state;

		const { currentUser, signedInWith } = this.props;
		let picture = '';
		let initials = '';
		if (currentUser) {
			if (currentUser.picture !== undefined && currentUser.picture !== null) {
				picture = currentUser.picture;
			}
			if (currentUser.firstName !== undefined && currentUser.firstName !== null) {
				// initials = currentUser.firstName.charAt(0).toUpperCase() + currentUser.firstName.slice(1) +  '.' + currentUser.lastName.charAt(0);
				initials = currentUser.firstName + ' ' + currentUser.lastName;
			} else {
				initials = 'Loading..';
			}
		}

		return (
			<div className="topbar__profile">
				<button className="topbar__avatar" type="button" onClick={this.toggle}>
					<p className="topbar__avatar-name">{initials}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						<TopbarMenuLink title="My Profile" icon="user" path="/account/profile" onClick={this.toggle} />
						<div className="topbar__menu-divider" />
						<TopbarMenuLink title="Log Out" icon="exit" path="/login" onClick={this.logout} />
					</div>
				</Collapse>
			</div>
		);
	}
}

// export default hookAuth0(TopbarProfile);

const topbarStateToProps = (state) => ({
	themeName: state.theme.className,
	rtl: state.rtl,
	currentUser: getCurrentUser(state),
	signedInWith: getSignedInWith(state)
});

export default compose(
	requireRole('user'),
	withTranslation('common'),
	connect(topbarStateToProps, {
		signOut
	})
)(TopbarProfile);
