import React from 'react';
import { connect } from 'react-redux';
import { getIsSignedIn, getDefaultPath, getAttemptPath } from '../redux/selectors';

import { withRouter } from 'react-router-dom';
import { setAttemptedPath } from '../redux/actions/authActions';

/**
 * If user already logged in, redirect to default path.
 *
 * @param {Component} WrappedComponent The component to be wrapped
 */
const requireAnonymous = () => (WrappedComponent) => {
	class ComposedComponent extends React.Component {
		componentDidMount() {
			this.shouldNavigateAway();
		}

		componentDidUpdate() {
			this.shouldNavigateAway();
		}

		shouldNavigateAway = () => {
			if (this.props.isSignedIn) {
				const { attemptPath, defaultPath } = this.props;
				if (attemptPath) {
					this.props.history.replace(attemptPath);
					this.props.setAttemptedPath(null);
				} else {
					this.props.history.replace(defaultPath);
				}
			}
		};

		render() {
			return <WrappedComponent {...this.props} />;
		}
	}

	const mapStateToProps = (state) => ({
		isSignedIn: getIsSignedIn(state),
		defaultPath: getDefaultPath(state),
		attemptPath: getAttemptPath(state)
	});

	return connect(mapStateToProps, {
		setAttemptedPath
	})(withRouter(ComposedComponent));
};

export default requireAnonymous;
