import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	tenant: {},
	tenants: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const tenantReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.SET_SWITCH_CLIENT:
			return {
				...state,
				error: null,
				tenant: {},
				tenants: []
			};
		case actionTypes.LOAD_TENANTS:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				tenants: []
			};
		case actionTypes.LOAD_TENANT:
			return {
				...state,
				processed: false,
				processing: true,
				tenant: {},
				error: null
			};
		case actionTypes.ADD_TENANT:
			return {
				...state,
				processed: false,
				processing: true,
				tenant: {},
				error: null
			};
		case actionTypes.UPDATE_TENANT:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_TENANTS_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				tenants: action.payload.tenants,
				defaultPath: '/login'
			};
		case actionTypes.LOAD_TENANT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				tenant: { ...action.payload.tenant }
			};
		case actionTypes.ADD_TENANT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				tenant: { ...action.payload.tenant }
			};
		case actionTypes.UPDATE_TENANT_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				tenant: { ...action.payload.tenant }
			};
		case actionTypes.LOAD_TENANTS_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.LOAD_TENANT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.UPDATE_TENANT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_TENANT_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default tenantReducer;
