import { createSelector } from 'reselect';

export const getSurveyState = (state) => state.survey;

export const getSurveysProcessing = createSelector(getSurveyState, (survey) => survey.processing);

export const getSurveysProcessed = createSelector(getSurveyState, (survey) => survey.processed);

export const getSurveysError = createSelector(getSurveyState, (survey) => survey.error);

export const getSurveys = createSelector(getSurveyState, (survey) => survey.surveys);

export const getSurvey = createSelector(getSurveyState, (survey) => survey.survey);
