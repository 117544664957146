import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import PublishTableFilterButton from './PublishTableFilterButton';

const PublishTableToolbar = ({ onRequestSort }) => (
	<div className="material-table__toolbar-wrap">
		<Toolbar className="material-table__toolbar">
			<div></div>
			<div>
				<PublishTableFilterButton onRequestSort={onRequestSort} />
			</div>
		</Toolbar>
	</div>
);

PublishTableToolbar.propTypes = {
	onRequestSort: PropTypes.func.isRequired
};

export default PublishTableToolbar;
