import { createSelector } from 'reselect';

export const getInformationState = (state) => state.info;

export const getInformationProcessing = createSelector(getInformationState, (info) => info.processing);

export const getInformationProcessed = createSelector(getInformationState, (info) => info.processed);

export const getInformationError = createSelector(getInformationState, (info) => info.error);

export const getInformations = createSelector(getInformationState, (info) => info.informations);

export const getInformation = createSelector(getInformationState, (info) => info.information);
