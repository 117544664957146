import React from 'react';
import PropTypes from 'prop-types';
import PublishTable from './components/PublishTable';
import { withRouter } from 'react-router';

const PublishModal = ({ onCloseClick }) => (
	<div className="publish-modal">
		<div className="publish-modal__background" onClick={onCloseClick}></div>
		<div className="publish-modal__table">
			<PublishTable />
		</div>
	</div>
);

PublishModal.propTypes = {
	onCloseClick: PropTypes.func.isRequired
};

export default withRouter(PublishModal);
