/* eslint-disable camelcase */
import common_fr from './fr/common.json';
import common_en from './en/common.json';
import common_de from './de/common.json';

export default {
	en: {
		common: common_en
	},
	fr: {
		common: common_fr
	},
	de: {
		common: common_de
	}
};
