import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MatTableHead from './PublishTableHead';
import PublishTableToolbar from './PublishTableToolbar';
import { getSwitchClient, getPublishData, getPublishError } from '../../../redux/selectors';
import { publishUpdates } from '../../../redux/actions/publishActions';
import Loading from '../../../shared/components/Loading';

import { compose } from 'redux';
import requireRole from '../../../hoc/requireRole';
import { connect } from 'react-redux';
import _ from 'lodash';

function getSorting(order, orderBy) {
	if (order === 'desc') {
		return (a, b) => {
			if (a[orderBy] < b[orderBy]) {
				return -1;
			}
			if (a[orderBy] > b[orderBy]) {
				return 1;
			}
			return 0;
		};
	}
	return (a, b) => {
		if (a[orderBy] > b[orderBy]) {
			return -1;
		}
		if (a[orderBy] < b[orderBy]) {
			return 1;
		}
		return 0;
	};
}

class PublishTable extends PureComponent {
	constructor() {
		super();
		this.state = {
			order: 'asc',
			orderBy: 'calories',
			data: [],
			page: 0,
			rowsPerPage: 10,
			isLoading: false
		};
	}

	toggle() {
		this.setState((prevState) => ({ modal: !prevState.modal }));
	}

	componentDidMount() {
		this.loadTableData();
	}

	loadTableData = async () => {
		this.setState({ isLoading: true });

		const { currentUser, switch_client } = this.props;
		let client;

		if (currentUser.role === 'root' && switch_client) {
			client = {
				_id: switch_client._id
			};
		} else if (currentUser.role === 'admin' || currentUser.role === 'user') {
			client = {
				_id: currentUser.cid
			};
		}
		const clientId = client && client._id;
		const userId = currentUser && currentUser._id;

		if (clientId && userId) {
			await this.props.publishUpdates({ clientId, userId });

			if (!this.props.errorMessage && this.props.updateDetails) {
				const data = this.props.updateDetails;
				console.log('loadTableData', data);

				this.setState({
					data
				});
			}
		}
		// if (client) {
		//   await this.props.loadClientEvents(client);

		//   if (!this.props.errorMessage && this.props.events) {
		//     const events = this.props.events;
		//     console.log('loadTableData', events);

		//     for (let index = 0; index < events.length; index++) {
		//       events[index]['id'] = index;
		//     }
		//     this.setState({
		//       data: events
		//     });
		//   }
		// }

		this.setState({ isLoading: false });
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';
		const { orderBy: stateOrderBy, order: stateOrder } = this.state;

		if (stateOrderBy === property && stateOrder === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value });
	};

	render() {
		const { data, order, orderBy, rowsPerPage, page, isLoading } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		let showTable = false;
		if (data.length > 0) {
			showTable = true;
		}

		return isLoading ? (
			<Loading loading={isLoading} loadNoBG />
		) : (
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h4 className="bold-text">{showTable ? `Update Status` : 'The client has no devices to be updated'}</h4>
						</div>

						{showTable && <PublishTableToolbar onRequestSort={this.handleRequestSort} />}

						{showTable && (
							<div className="material-table__wrap">
								<Table className="material-table">
									<MatTableHead order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} rowCount={data.length} />
									<TableBody>
										{data
											.sort(getSorting(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((d) => {
												return (
													<TableRow className="material-table__row" tabIndex={-1} key={d.deviceId}>
														<TableCell className="material-table__cell material-table__cell-right" component="th" scope="row" padding="none">
															{d.deviceName}
														</TableCell>

														<TableCell className="material-table__cell material-table__cell-right">{d.deviceLocation}</TableCell>

														<TableCell className="material-table__cell material-table__cell-right">{d.status ? 'Success' : 'Failed'}</TableCell>
													</TableRow>
												);
											})}
										{emptyRows > 0 && (
											<TableRow style={{ height: 49 * emptyRows }}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</div>
						)}

						{showTable && (
							<TablePagination
								component="div"
								className="material-table__pagination"
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								backIconButtonProps={{ 'aria-label': 'Previous Page' }}
								nextIconButtonProps={{ 'aria-label': 'Next Page' }}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
								rowsPerPageOptions={[5, 10, 15]}
								dir="ltr"
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true
								}}
							/>
						)}
					</CardBody>
				</Card>
			</Col>
		);
	}
}

const mapStateToProps = (state) => ({
	themeName: state.theme.className,
	rtl: state.rtl,
	errorMessage: getPublishError(state),
	updateDetails: getPublishData(state),
	switch_client: getSwitchClient(state)
});

export default compose(
	connect(mapStateToProps, {
		publishUpdates
	}),
	requireRole('user')
)(PublishTable);
