import { createSelector } from 'reselect';

export const getStaticTenantState = (state) => state.staticTenant;

export const getStaticTenantsProcessing = createSelector(getStaticTenantState, (tenant) => tenant.processing);

export const getStaticTenantsProcessed = createSelector(getStaticTenantState, (tenant) => tenant.processed);

export const getStaticTenantsError = createSelector(getStaticTenantState, (tenant) => tenant.error);

export const getStaticTenants = createSelector(getStaticTenantState, (tenant) => tenant.tenants);

export const getStaticTenant = createSelector(getStaticTenantState, (tenant) => tenant.tenant);
