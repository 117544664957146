import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
	device: {},
	devices: [],
	processing: false,
	processed: false,
	error: null,
	defaultPath: '/login', // Used as a default redirect path
	attemptedPath: null // Used to redirect users to the page they visited before logging in
};

const devicesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOAD_DEVICES:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_DEVICE:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				device: {}
			};
		case actionTypes.ADD_DEVICE:
			return {
				...state,
				processed: false,
				processing: true,
				error: null,
				device: {}
			};
		case actionTypes.UPDATE_DEVICE:
			return {
				...state,
				processed: false,
				processing: true,
				error: null
			};
		case actionTypes.LOAD_DEVICES_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				devices: action.payload.devices,
				defaultPath: '/device/list'
			};
		case actionTypes.LOAD_DEVICE_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				device: { ...action.payload.device }
			};
		case actionTypes.ADD_DEVICE_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				device: { ...action.payload.device }
			};
		case actionTypes.UPDATE_DEVICE_SUCCESS:
			return {
				...state,
				processing: false,
				processed: true,
				device: { ...action.payload.device }
			};
		case actionTypes.LOAD_DEVICES_FAIL:
		case actionTypes.LOAD_DEVICE_FAIL:
		case actionTypes.UPDATE_DEVICE_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		case actionTypes.ADD_DEVICE_FAIL:
			return {
				...state,
				processing: false,
				processed: true,
				error: action.payload
			};
		default:
			return state;
	}
};

export default devicesReducer;
